import { ID } from "./snowflake.interface";

export interface ITokenResponse {
    readonly jwt: string;
    readonly user: IIdentity
}

export interface IRole {
    id: ID
    name: string
    type: string
    description: string
}

export interface IIdentity {
    readonly id: number;
    readonly createdDateTime: string;
    givenName: string;
    familyName: string;
    email: string;
    emailVerified: boolean;
    status: string;
    role: IRole;
    language: string;
}

export interface IAuthenticationParams {
    identifier: string;
    password: string;
}
