import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, debounceTime, distinctUntilChanged, map, tap, timeout } from 'rxjs';
import { FromApi, IIndustry, ILanguage, Paginated } from '../interfaces';
import { ENVIRONMENT } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api/industries`;
  private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;
  private readonly debounceTime: number = 1000;

  public constructor(private readonly httpClient: HttpClient) { }

  public list(): Observable<IIndustry[]> {
    const REQUEST_URL = this.endpoint

    return this.httpClient
      .get<Paginated<FromApi<IIndustry>>>(REQUEST_URL, {
        withCredentials: true,
      })
      .pipe(
        debounceTime(this.debounceTime),
        timeout(this.requestTimeout),
        distinctUntilChanged(),
        map(value => value.data.map(v => ({
          id: v.id,
          name: v.attributes.name,
        } satisfies IIndustry)))
      )
  }
}
