import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, race, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { IApplication, IApplicationSlice } from '@core/interfaces';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AccountService, ApplicationService } from '@core/api';
import {
  delay,
  filter,
  finalize,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ObjectExt } from '@core/utils';
import { ActivatedRoute, Params } from '@angular/router';
import { ID } from '@app/core/interfaces/snowflake.interface';
import { DialogService } from '@ngneat/dialog';
import { ConfirmDialogComponent } from '@app/modules/accounts/components/account-delete-dialog/account-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PersonalInfoService } from '@app/core/api/personal-info.service';

@Component({
  selector: 'lca-application-listing',
  templateUrl: './application-listing.component.html',
  styleUrls: ['./application-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationListingComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly destroyed$: Subject<void> = new Subject<void>();
  public readonly loading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public dataSource: MatTableDataSource<IApplication> =
    new MatTableDataSource<IApplication>([]);
  public columns: string[] = ['user', 'status', 'actions'];
  public pageSize = 10;
  public pageSizeOptions: number[] = [10, 50, 100];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly personnalInfoService: PersonalInfoService,
    private readonly dialog: MatDialog // Injectez MatDialog ici
  ) {}

  public ngOnInit(): void {
    this.load();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public ngAfterViewInit(): void {
    race(
      this.loading$.pipe(
        filter((v) => !v),
        withLatestFrom(
          this.activatedRoute.queryParams.pipe(
            filter((v) => !ObjectExt.isEmptyObject(v)),
            take(1)
          )
        ),
        delay(100),
        switchMap(([_, params]: [boolean, Params]): Observable<never> => {
          if (this.dataSource.paginator instanceof MatPaginator) {
            this.dataSource.paginator.pageSize = params.page_size;
            for (let i = 0; i < params.page_index; i++) {
              this.dataSource.paginator.nextPage();
            }
          }
          return EMPTY;
        }),
        take(1)
      )
    ).subscribe();
  }

  public openDeleteDialog(employee: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {});

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.personnalInfoService.deleteOneByUid(employee).subscribe(
          () => {
            this.reload();
          },
          (error) => {
            console.error('Erreur lors de la suppression', error);
          }
        );
      }
    });
  }

  public trackByUid(index: number, item: IApplication): ID {
    return item.id;
  }

  public reload(): void {
    if (!this.loading$.value) {
      this.load();
    }
  }

  public filter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.dataSource.filterPredicate = (data: IApplication, filter: string) => {
      const accumulator = (currentTerm: unknown, key: string) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      // @ts-ignore
      let dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      dataStr += `${data.personalInfo?.givenName || ''} ${
        data.personalInfo?.familyName || ''
      }`.toLowerCase();
      dataStr += `${data.personalInfo?.familyName || ''} ${
        data.personalInfo?.givenName || ''
      }`.toLowerCase();

      const transformedFilter = filterValue.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private load(): void {
    this.loading$.next(true);

    this.applicationService
      .slice({
        filter: location.search,
        sort: `id:DESC`,
        pagination: {
          pageSize: this.pageSize + 10000,
          page: this.paginator.pageIndex + 1,
        },
      })
      .pipe(
        take(1),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((resp: IApplicationSlice): IApplicationSlice => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.data = resp.data;

        return resp;
      });
  }

  private nestedFilterCheck(search: any, data: any, key: any): unknown {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }
}
