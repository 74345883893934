import {Pipe, PipeTransform} from '@angular/core';
import {AccountRole} from '@core/enums';

@Pipe({
    name: 'role'
})
export class RolePipe implements PipeTransform {

    private readonly roles: Array<{ key: string, value: string }> = Object.keys(AccountRole).map((key: string): { key: string, value: string } => {
        return {
            key,
            value: AccountRole[key as keyof typeof AccountRole]
        };
    });

    public transform(value: string): string {
        return this.roles.find(v => v.value === value)?.key as string;
    }

}
