import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { Observable, throwError, timeout } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IPersonalInfo,
  IPersonalInfoRaw,
} from '../interfaces/account.interface';
import { ENVIRONMENT } from '@environment';
import { ID } from '../interfaces/snowflake.interface';

@Injectable({
  providedIn: 'root',
})
export class PersonalInfoService {
  private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api/personal-infos`;
  private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;

  public constructor(private readonly httpClient: HttpClient) {}

  public create(payload: Omit<IPersonalInfoRaw, 'id'>): Observable<ID> {
    return this.httpClient
      .post<{ data: IPersonalInfo }>(
        this.endpoint,
        { data: payload },
        { withCredentials: true }
      )
      .pipe(timeout(this.requestTimeout))
      .pipe(map((info) => info.data.id));
  }

  public update(
    payload: Omit<IPersonalInfoRaw, 'id'> & { id: ID }
  ): Observable<ID> {
    const REQUEST_URL = `${this.endpoint}/${payload.id}`;
    return this.httpClient
      .put<{ data: IPersonalInfo }>(
        REQUEST_URL,
        { data: payload },
        { withCredentials: true }
      )
      .pipe(timeout(this.requestTimeout))
      .pipe(map((info) => info.data.id));
  }

  public deleteOneByUid<T extends boolean>(accountUID: any): Observable<T> {
    const REQUEST_URL = `${this.endpoint}`;
    console.log(`${REQUEST_URL}/${accountUID}`);
    return this.httpClient
      .delete<T>(`${REQUEST_URL}/${accountUID}`, {
        withCredentials: true,
        observe: 'response',
        responseType: 'json',
        headers: new HttpHeaders({}),
        params: new HttpParams({}),
      })
      .pipe(
        debounceTime(1000),
        timeout(ENVIRONMENT.api.readTimeout),
        distinctUntilChanged(),
        map(
          (response: HttpResponse<any>): T =>
            (response.status === 204 || response.status === 200) as T
        ),
        catchError((error: unknown): Observable<never> => throwError(error))
      );
  }
}
