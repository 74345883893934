<ng-container *ngIf="(identity$ | async) as identity">
    <lca-app-header [identity]="identity" [drawerMode]="drawerMode" (toggleDrawerEvent)="toggleDrawer($event)" (submitSignOutEvent)="signOut()"></lca-app-header>
    <mat-sidenav-container role="navigation">
        <mat-sidenav [mode]="drawerMode" [opened]="drawerState$ | async">
            <ng-template [ngIf]="drawerMode === 'over'"></ng-template>
            <mat-nav-list>
                <a mat-list-item [routerLink]="['/']" routerLinkActive="sidenav-active" [routerLinkActiveOptions]="{exact: true}">Dashboard</a>
                <a mat-list-item [routerLink]="['/accounts']" routerLinkActive="sidenav-active">Accounts</a>
                <a mat-list-item [routerLink]="['/companies']" routerLinkActive="sidenav-active">Unternehmen</a>
                <a mat-list-item [routerLink]="['/applications']" routerLinkActive="sidenav-active">Bewerberprofile</a>
                <a mat-list-item [routerLink]="['/jobs']" routerLinkActive="sidenav-active">Stellenausschreibungen</a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content role="main">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
