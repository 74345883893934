<input (change)="onFileSelected($event)"
       hidden
       type="file"
       aria-hidden="true"
       [name]="fieldName"
       [accept]="acceptMimeType"
       accept="application/pdf"
       #fileSelector>

<button mat-stroked-button
        type="button"
        (click)="fileSelector.click()"
        [disabled]="disabled"
        *ngIf="!(hide$ | async)"
        class="file-btn">{{buttonText}}
</button>
