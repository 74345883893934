import { Injectable, OnDestroy } from '@angular/core';
import { ENVIRONMENT } from '@environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, iif, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, tap, distinctUntilChanged } from 'rxjs/operators';
import { IAuthenticationParams, IIdentity, ITokenResponse } from '@core/interfaces';
import { Router } from '@angular/router';
import { AUTH_ROUTES } from '@app/modules/auth/auth-routing.module';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
    private readonly destroyed$: Subject<void> = new Subject<void>();
    private identitySubject: BehaviorSubject<IIdentity | undefined>;
    public readonly identity: Observable<IIdentity | undefined>

    public constructor(private readonly httpClient: HttpClient, private router: Router) {
        this.identitySubject = new BehaviorSubject(undefined)
        this.identity = this.identitySubject.asObservable()
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private static readonly accessToken: string = 'accessToken';
    private readonly base: string = ENVIRONMENT.api.gatewayBaseUrl;

    private clearToken(): void {
        localStorage.removeItem(AuthenticationService.accessToken);
    }

    private storeToken(token?: string): void {
        if (token !== undefined) {
            localStorage.setItem(AuthenticationService.accessToken, token);
        } else {
            this.clearToken();
        }
    }

    public authenticate(payload: IAuthenticationParams): Observable<IIdentity | { error: any }> {
        const REQUEST_URL = `${this.base}/api/auth/local`;

        return this.httpClient
            .post<ITokenResponse>(REQUEST_URL, payload, {
                withCredentials: true
            })
            .pipe(
                mergeMap(value => {
                    if (value instanceof HttpErrorResponse) {
                        throw value
                    }

                    this.storeToken(value.jwt)
                    return this.refreshSession()
                }),
            );
    }

    private setIdentity(u: IIdentity | undefined): void {
        this.identitySubject.next(u)
    }

    public invalidate(): void {
        this.clearToken();
        this.setIdentity(undefined)
        void this.router.navigate([AUTH_ROUTES.MODULE, AUTH_ROUTES.SIGN_IN])
    }

    public getToken(): string | null {
        return localStorage.getItem(AuthenticationService.accessToken);
    }

    public get isAuthenticated(): Observable<boolean> {
        return this.identity.pipe(
            distinctUntilChanged(),
            mergeMap((i) => {
                if (i) {
                    return of(true)
                }

                if (this.getToken()) {
                    return this.refreshSession().pipe(map(i => Boolean(i)))
                }

                return of(false)
            })
        )
    }

    public get hasIdentity(): Observable<IIdentity | undefined> {
        return this.identity.pipe(
            distinctUntilChanged(),
            mergeMap((i) => {
                if (i) {
                    return of(i)
                }

                if (this.getToken()) {
                    return this.refreshSession()
                }

                return of(undefined)
            })
        )
    }

    public refreshSession(): Observable<any | undefined> { // TODO replace any with IIdentity
        return this.httpClient
            .get<any>( // TODO replace any with IIdentity
                `${this.base}/api/users/me`,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${this.getToken()}`
                    }
                }
            )
            .pipe(
                catchError(() => of(undefined)),
                map((i) => i?.role === 'admin' ? i : undefined),
                tap((i) => {
                    this.setIdentity(i)
                    if (!i) {
                        this.clearToken()
                    }
                })
            )
    }
}
