import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AccountListingComponent} from '@modules/accounts/features/account-listing/account-listing.component';
import {AccountFormComponent} from '@modules/accounts/features/account-form/account-form.component';
import {AccountResolver} from '@core/resolver';

export const ACCOUNT_ROUTES = {
    MODULE: 'accounts',
    ROOT: '',
    CREATE: 'create',
    MODIFY: ':id'
};

const ROUTES: Routes = [
    {
        path: ACCOUNT_ROUTES.ROOT,
        component: AccountListingComponent
    },
    {
        path: ACCOUNT_ROUTES.CREATE,
        component: AccountFormComponent
    },
    {
        path: ACCOUNT_ROUTES.MODIFY,
        component: AccountFormComponent,
        resolve: {
            account: AccountResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class AccountsRoutingModule {
}
