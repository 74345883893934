import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, EMPTY, noop, Observable, race, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ICompany, ICompanySlice } from '@core/interfaces';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CompanyService } from '@core/api';
import {
  delay,
  filter,
  finalize,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ObjectExt } from '@core/utils';
import { ActivatedRoute, Params } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ID } from '@app/core/interfaces/snowflake.interface';

@Component({
  selector: 'lca-company-listing',
  templateUrl: './company-listing.component.html',
  styleUrls: ['./company-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyListingComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly destroyed$: Subject<void> = new Subject<void>();
  public readonly loading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public dataSource: MatTableDataSource<ICompany> =
    new MatTableDataSource<ICompany>([]);
  public columns: string[] = ['name', 'status', 'actions'];
  public pageSize = 10;
  public pageSizeOptions: number[] = [10, 50, 100];

  public textSearchFilter = new FormControl<string>('');

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly companyService: CompanyService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.load();

    this.textSearchFilter.valueChanges.subscribe((value) => {
      this.dataSource.filter = value.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });

    this.dataSource.filterPredicate = (data: ICompany, filter: string) => {
      return data.name.toLowerCase().includes(filter);
    };
  }

  public ngAfterViewInit(): void {
    race(
      this.loading$.pipe(
        filter((v) => !v),
        withLatestFrom(
          this.activatedRoute.queryParams.pipe(
            filter((v) => !ObjectExt.isEmptyObject(v)),
            take(1)
          )
        ),
        delay(100),
        switchMap(([_, params]: [boolean, Params]): Observable<never> => {
          if (this.dataSource.paginator instanceof MatPaginator) {
            this.dataSource.paginator.pageSize = params.page_size;
            for (let i = 0; i < params.page_index; i++) {
              this.dataSource.paginator.nextPage();
            }
          }
          return EMPTY;
        }),
        take(1)
      )
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public trackByUid(_: number, item: ICompany): ID {
    return item.id;
  }

  public reload(): void {
    if (!this.loading$.value) {
      this.load();
    }
  }

  private load(): void {
    this.loading$.next(true);

    this.companyService
      .slice()
      .pipe(
        take(1),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((resp: ICompanySlice) => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.data = resp.data;

        console.log(resp);
        return resp;
      });
  }
}
