import { ID } from './snowflake.interface';

export {
    IAccountSlice,
    IAccount
} from './account.interface';

export {
    IApplication,
    IApplicationSlice
} from './application.interface';

export {
    IApplyingForOptions,
    IApplyingForOptionsSlice,
    IIndustry,
    IIndustrySlice,
    IExperienceIn,
    IExperienceInSlice,
    IEducation,
    IEducationSlice,
    IInCompanyTraining,
    IInCompanyTrainingSlice,
    ILanguageLevel,
    ILanguage,
    ILanguageLevelSlice,
    ILanguageSlice,
    IWorkExperience
} from './application-value.interface';

export {
    ICompany,
    ICompanySlice
} from './company.interface';

export {
    IAuthenticationParams,
    ITokenResponse,
    IIdentity
} from './identity.interface';

export {
    IJob,
    IJobSlice
} from './job.interface';

export {
    IFile
} from './file.interface';

export interface Paginated<T> {
    data: T[]
    meta: {
        pagination: {
            page: number
            pageCount: number
            pageSize: number
            total: number
        }
    }
}

export interface FromApi<T, F extends keyof T|'' = ''> {
    id: ID
    attributes: {
        [K in keyof Omit<T, 'id'>]: K extends F
            ? (T[K] extends Array<any> ? { data: FromApi<T[K][0]>[] } : { data: FromApi<T[K]> })
            : T[K]
    }
}