import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { AccountService } from '@core/api';
import { IAccount } from '@core/interfaces';
import { catchError, tap } from 'rxjs/operators';
import { ID } from '../interfaces/snowflake.interface';
import { ACCOUNT_ROUTES } from '@app/modules/accounts/accounts-routing.module';

@Injectable({
    providedIn: 'root'
})
export class AccountResolver {

    constructor(private accountService: AccountService,
        private router: Router) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccount> | any {
        let id: ID;

        if (!route.paramMap.get('id')) {
            if (route.parent != null) {
                if (!route.parent.paramMap.get('id')) {
                    void this.router.navigate([ACCOUNT_ROUTES.MODULE]);
                    return;
                }

                id = parseInt(route.parent.paramMap.get('id'));
            }
        } else {
            id = parseInt(route.paramMap.get('id'));
        }

        if (isNaN(id)) {
            void this.router.navigate([ACCOUNT_ROUTES.MODULE])
            return
        }

        return this.accountService
            .get(id)
            .pipe(
                tap(account => {
                    if (!account?.id) {
                        throw new Error('Invalid user')
                    }
                }),
                catchError(() => {
                    void this.router.navigate([ACCOUNT_ROUTES.MODULE]);
                    return EMPTY;
                })
            );
    }

}
