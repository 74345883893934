import { ICompany, IFile, Paginated } from '@core/interfaces';
import { ID } from './snowflake.interface';
import { IWorkExperience } from './work-experience.interface';
import { ICompanyAddress } from './company.interface';
import { IEmployementType } from './employement-type.interface';

export enum JobStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum employmentContract {
    UNLIMITED = 'UNLIMITED',
    LIMITED = 'LIMITED'
}

export interface IVacancyContact {
    givenName: string
    familyName: string
    email: string
    phoneNumber: string
    user?: {
        email: string
    }
}

export interface IJobBase {
    id: ID
    status: JobStatus
    title: string
    description: string
    workHours: number
    start_date: Date
    end_date: Date
    offering: string
    requirements: string
    employmentContract: employmentContract
    location: Array<ICompanyAddress>
    contacts: Array<IVacancyContact>
}

export type IJob = IJobBase & {
    employmentType: IEmployementType
    attachments: Array<IFile>
    company: ICompany
    kinds: Array<IEmployementType>
}

export type IJobRaw = IJobBase & {
    employmentType: ID
    attachments: Array<ID>
    company: ID
    kinds: Array<ID>
}

export type IJobSlice = Paginated<IJob>