<ng-container *ngIf="application">
    <div class="applicant-view__container">
        <header class="applicant-view__header">
            <div class="applicant-view__header__name--container">
                <div class="applicant-view__header__name">
                    <h1>{{(application.personalInfo?.givenName ?? "" ) }} {{(application.personalInfo?.familyName ?? "")}}
                         {{ !(application.personalInfo?.givenName && application.personalInfo?.familyName) ? "Anonymous Applicant" : ""}}</h1>
                </div>
                <div class="applicant-view__header__overlay"></div>
            </div>

            <ng-container *ngIf="application.portrait">
                <img [src]="fileServerBase + application.portrait?.url"
                    [alt]="(application.personalInfo?.givenName ?? '') + ' ' + (application.personalInfo?.familyName ?? '')"
                    [title]="(application.personalInfo?.givenName ?? '') + ' ' + (application.personalInfo?.familyName ?? '')"
                    class="portrait">
            </ng-container>
        </header>

        <section *ngIf="application.personalInfo.user">
            <a mat-stroked-button [routerLink]="['/accounts', application.personalInfo.user?.id]">Zum Account</a>
        </section>

        <section class="view-section">
            <h1 class="mat-title">Sucht</h1>

            <div class="seeking-kind__list">
                <span *ngFor="let looking_for of application.lookingFors" class="seeking-kind__list__kinds">
                    {{looking_for.name}}
                </span>
            </div>

            <!-- <div class="seeking-kind__list">
                <span>im Bereich</span>&nbsp;
                <span *ngFor="let industry of application.businesses"
                    class="seeking-kind__list__kinds">{{industry}}</span>
            </div> -->

            <div>
                <ng-template [ngIf]="application.begin_date">
                    <span>von {{application.begin_date | date }} bis {{application.end_date | date}}</span>
                </ng-template>
                <ng-template [ngIf]="application.date_in_consultation">
                    <span>Nach Absprache</span>
                </ng-template>
            </div>
        </section>

        <mat-divider></mat-divider>

        <section class="view-section">
            <h1 class="mat-title">Bildung und Erfahrung</h1>

            <ng-template [ngIf]="application.workExperience.companyTrainings.length > 0">
                <div style="margin: 24px 0px">
                    <h3>Ausbildungen</h3>
                    <ul>
                        <li *ngFor="let training of application.workExperience.companyTrainings">
                            {{training.name}}
                        </li>
                        <li *ngIf="application.workExperience.other_training">
                            {{application.workExperience.other_training}}
                        </li>
                    </ul>

                </div>
            </ng-template>

            <ng-template [ngIf]="application.degrees && application.degrees.degrees.length > 0">
                <div style="margin: 24px 0px">
                    <h3 style="margin-top: 12px" *ngIf="application.degrees?.type === 'DEGREE'; else notGraduated">
                        Studierte</h3>
                    <ng-template #notGraduated>
                        <h3 style="margin-top: 12px">Studie</h3>
                    </ng-template>
                    <ul>
                        <li *ngFor="let education of application.degrees.degrees">{{education.name}}</li>
                    </ul>
                </div>
            </ng-template>

            <div style="margin: 24px 0px">
                <h3 style="margin-top: 12px">Erfahrung in</h3>
                <ul>
                    <li *ngFor="let experienceIn of application.workExperience.experienceIns">
                        {{experienceIn.name}}
                    </li>
                    <li *ngIf="application.workExperience.other_experience">
                        {{application.workExperience.other_experience}}
                    </li>
                </ul>
            </div>
        </section>

        <mat-divider></mat-divider>

        <section class="view-section">
            <h3>Sprachen</h3>
            <ul>
                <li *ngFor="let language of application.languages">
                    <span>{{language.language.name}}</span> - <span>{{language.level.name}}</span>

                    <ng-container [ngSwitch]="language.level">
                        <span *ngSwitchCase="'BASIC'">Grundkenntnisse</span>
                        <span *ngSwitchCase="'CONVERSATIONAL'">Konversationssischer</span>
                        <span *ngSwitchCase="'FLUENT'">Verhandlungssicher</span>
                        <span *ngSwitchCase="'MOTHER_TONGUE'">Muttersprache</span>
                    </ng-container>
                </li>
            </ul>
        </section>

        <mat-divider></mat-divider>

        <section class="view-section">
            <h3>Lebenslauf</h3>
            <a mat-flat-button [href]="fileServerBase + application.resume?.url" target="_blank" class="media-btn"
                color="accent">Lebenslauf ansehen</a>
        </section>

        <section class="view-section" *ngIf="application.certificates">
            <h3>Zeugnisse</h3>
            <div *ngFor="let testimony of application.certificates; let i = index">
                <a mat-flat-button [href]="fileServerBase + testimony.url" target="_blank" class="media-btn"
                    color="accent">Zeugnisse ansehen</a>
            </div>
        </section>
    </div>
</ng-container>