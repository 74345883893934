interface IHttpConnection {
  ssl: boolean;
  gatewayBaseUrl: string;
  gatewayVersion: string | number | null;
  apiVersion: string;
  apiVersionNumerical: number;
  staticBaseUrl?: string;
  fileServerBaseUrl: string;
  readTimeout: number;
  writeTimeout: number;
}

export interface IEnvironment {
  debug?: boolean;
  production?: boolean;
  api: IHttpConnection;
}

export const DEFAULT_API_VERSION = `v1`;
export const DEFAULT_API_VERSION_NUMERICAL = 1;
export const DEFAULT_READ_TIMEOUT = 8000;
export const DEFAULT_WRITE_TIMEOUT = 8000;

export const DEFAULTS: IEnvironment = {
  production: false,
  debug: true,
  api: {
    ssl: true,
    gatewayBaseUrl: `http://localhost:1337`,
    // gatewayBaseUrl: `https://api-v2.dev.nectanet.actihosting.de`,
    gatewayVersion: 1,
    apiVersion: DEFAULT_API_VERSION ?? `v1`,
    apiVersionNumerical: DEFAULT_API_VERSION_NUMERICAL ?? 1,
    fileServerBaseUrl: `http://localhost:1337`,
    // fileServerBaseUrl: `https://api-v2.dev.nectanet.actihosting.de`,
    staticBaseUrl: `/assets/data`,
    readTimeout: DEFAULT_READ_TIMEOUT ?? 8000,
    writeTimeout: DEFAULT_WRITE_TIMEOUT ?? 8000,
  },
};
