import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Subject, Observable, take, finalize } from 'rxjs';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { ICompany, IFile, IIndustry } from '@core/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyStatus } from '@core/enums';
import { ObjectExt } from '@core/utils';
import { ENVIRONMENT } from '@environment';
import { NotificationService } from '@core/services';
import {
  ICompanyActivities,
  ICompanyAddress,
  ICompanyRaw,
  IVacancy,
} from '@app/core/interfaces/company.interface';
import { CompanyService } from '@app/core/api';
import { HttpErrorResponse } from '@angular/common/http';
import { RecursivePartial } from '@app/core/utils/recursive-partials';

type CompanyFields = Omit<ICompany, 'id' | 'created_at' | 'updated_at'>;
type AddressesForm = {
  [K in keyof Omit<ICompanyAddress, 'id'>]: FormControl<ICompanyAddress[K]>;
};
type VacancyForm = {
  [K in keyof Omit<IVacancy, 'id'>]: FormControl<IVacancy[K]>;
};
type ActivityForm = {
  [K in keyof ICompanyActivities]: FormControl<ICompanyActivities[K]>;
};
export type CompanyForm = {
  [K in keyof Omit<
    CompanyFields,
    'addresses' | 'vacancy' | 'activities' | 'industry'
  >]: FormControl<CompanyFields[K]>;
} & {
  addresses: FormArray<FormGroup<AddressesForm>>;
  vacancy: FormArray<FormGroup<VacancyForm>>;
  activities: FormGroup<ActivityForm>;
  industry: FormControl<IIndustry>;
};

@Component({
  selector: 'lca-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyFormComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly destroyed$: Subject<void> = new Subject<void>();
  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public readonly logo$: BehaviorSubject<IFile | null> =
    new BehaviorSubject<IFile | null>(null);
  public readonly fileServerBase: string = ENVIRONMENT.api.fileServerBaseUrl;

  public company: ICompany;
  public companyForm: FormGroup<CompanyForm>;
  public companyFormControls: {};

  public statusSlice: Array<{ key: string; value: string }> = [];
  protected industryOptions: IIndustry[] = [];

  public get locationsFormArray(): FormArray<FormGroup<AddressesForm>> {
    return this.companyForm.controls.addresses;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly companyService: CompanyService,
    private readonly notifier: NotificationService,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.company = { ...this.route.snapshot.data.company };
    this.industryOptions = [...this.route.snapshot.data.industries];
    this.companyForm = this.formFactory();
  }

  public ngOnInit(): void {
    this.statusSlice = Object.keys(CompanyStatus).map(
      (key: string): { key: string; value: string } => {
        return {
          key,
          value: CompanyStatus[key as keyof typeof CompanyStatus],
        };
      }
    );
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public ngAfterViewInit(): void {
    if (!ObjectExt.isEmptyObject(this.company) && this.company.id) {
      this.populate();
    }
  }

  public addLogo(event: IFile[]): void {
    const file = event?.[0];
    this.companyForm.controls.logo.setValue(file);
    this.logo$.next(file);
  }

  private populate(): void {
    this.companyForm.reset();

    this.logo$.next(this.company.logo ?? null);
    this.company.logo.id = this.company?.logo?.id;

    if (this.companyForm.controls.addresses.length === 0) {
      this.company.addresses.forEach(() => this.addAddress());
    }

    this.companyForm.patchValue({
      ...this.company,
    });
    this.cdRef.markForCheck();
  }

  public submit(): void {
    if (this.companyForm.invalid) {
      markAsDirtyRecursive(this.companyForm);

      this.cdRef.markForCheck();
      console.log('this form', this.companyForm);
      this.notifier.error('Bitte Eingaben überprüfen.');
      this.cdRef.detectChanges();
      return;
    }

    this.loading$.next(true);

    const VALUES: RecursivePartial<ICompanyRaw> = {
      ...this.companyForm.value,
      logo: this.companyForm.controls?.logo?.value?.id,
      photo: undefined,
      vacancy: undefined,
    };

    let crudAction: Observable<ICompany>;
    if (this.company.id) {
      crudAction = this.companyService.modify(this.company.id, VALUES);
    } else {
      crudAction = this.companyService.create(VALUES);
    }

    crudAction
      .pipe(
        take(1),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe({
        next: (resp: ICompany): ICompany => {
          void this.router.navigate([`/companies/${resp.id}`], { replaceUrl: true });
          return resp;
        },
        error: (err: HttpErrorResponse) => {
          return err;
        },
      });
  }

  private formFactory(): FormGroup<CompanyForm> {
    const FORM = this.formBuilder.group<CompanyForm>(
      {
        name: this.formBuilder.control(null, Validators.required),
        ceo: this.formBuilder.control(null),
        logo: this.formBuilder.control(null),
        employees: this.formBuilder.control(null),
        careerOpportunities: this.formBuilder.control(null),
        industry: this.formBuilder.control(null, Validators.required),
        about: this.formBuilder.control(null, Validators.required),
        website: this.formBuilder.control(null, Validators.required),
        status: this.formBuilder.control(null, Validators.required),
        addresses: this.formBuilder.array<FormGroup<AddressesForm>>([]),
        vacancy: this.formBuilder.array<FormGroup<IVacancy>>([]),
        activities: this.formBuilder.group<ActivityForm>({
          isLeading: this.formBuilder.control(false, Validators.required),
          service: this.formBuilder.control(false, Validators.required),
          gastronomy: this.formBuilder.control(false, Validators.required),
          health: this.formBuilder.control(false, Validators.required),
          science: this.formBuilder.control(false, Validators.required),
          industryCrafting: this.formBuilder.control(false, Validators.required),
        }),
      },
      {
        validators: [
          (form: FormGroup) => {
            if (!Boolean((form.controls.addresses as FormArray).length)) {
              return {
                addressRequired: !Boolean((form.controls.addresses as FormArray).length),
              };
            } else {
              return null;
            }
          },
        ],
      }
    );

    return FORM;
  }

  public addAddress(): void {
    const addressForm = this.formBuilder.group<AddressesForm>(
      {
        city: this.formBuilder.control(null, Validators.required),
        country: this.formBuilder.control(null, Validators.required),
        postalCode: this.formBuilder.control(null, [
          Validators.minLength(0),
          Validators.maxLength(5),
          Validators.min(0o1001),
          Validators.max(99998),
        ]),
        street: this.formBuilder.control(null, Validators.required),
        streetNumber: this.formBuilder.control(null, Validators.required),
        headquarter: this.formBuilder.control(null),
      },
      {
        validators: [Validators.required, Validators.minLength(1)],
      }
    );

    this.companyForm.controls.addresses.push(addressForm);
  }

  public removeLocation(index: number): void {
    this.companyForm.controls.addresses.removeAt(index);
  }
  public isValidTab(tab: string): boolean {
    switch (tab) {
      case 'address':
        return (
          this.companyForm.touched &&
          (this.companyForm.controls.addresses.status === 'INVALID' ||
            this.companyForm.errors?.addressRequired)
        );
      case 'core':
        return (
          this.companyForm.touched &&
          (this.companyForm.controls.about.status === 'INVALID' ||
            this.companyForm.controls.industry.status === 'INVALID' ||
            this.companyForm.controls.ceo.status === 'INVALID' ||
            this.companyForm.controls.activities.status === 'INVALID' ||
            this.companyForm.controls.website.status === 'INVALID' ||
            this.companyForm.controls.employees.status === 'INVALID')
        );
      default:
        return true;
    }
  }
}

export const markAsDirtyRecursive = (formGroup: FormGroup) => {
  Object.keys(formGroup.controls).forEach((key) => {
    if ((formGroup.controls[key] as FormGroup).controls) {
      if ((formGroup.controls[key] as FormGroup).controls instanceof Array) {
        for (let grp of (formGroup.controls[key] as FormGroup)
          .controls as unknown as Array<FormGroup>) {
          markAsDirtyRecursive(grp);
        }
      } else {
        markAsDirtyRecursive(formGroup.controls[key] as FormGroup);
      }
    } else {
      formGroup.controls[key].updateValueAndValidity();
      formGroup.controls[key].markAsDirty();
      formGroup.controls[key].markAsTouched();
    }
  });
};
