import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {asapScheduler, combineLatest, Observable, scheduled, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {finalize, take} from 'rxjs/operators';
import { IJob } from '@app/core/interfaces';
import { employmentContract } from '@app/core/interfaces/job.interface';

@Component({
    selector: 'lca-job-view',
    templateUrl: './job-view.component.html',
    styleUrls: ['./job-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobViewComponent implements OnInit, OnDestroy {
    private readonly destroyed$: Subject<void> = new Subject<void>();

    private readonly _vacancy$: Subject<IJob> = new Subject<IJob>();
    public readonly vacancy$: Observable<any> = this._vacancy$.asObservable(); // TODO replace any with IJob

    protected vacancy_contracts: Record<string, string> = {}
    protected vacancy_types: Record<string, string> = {}
    protected vacancy_kinds: string[] = []

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        scheduled<IJob>([this.activatedRoute.snapshot.data.job], asapScheduler)
            .pipe(
                finalize(() => {
                    this.cdRef.markForCheck();
                }),
                take(1)
            )
            .subscribe((vacancy) => {
                this.vacancy_kinds.push(...vacancy.kinds.map(k=>k.name))

                if (vacancy.employmentContract === employmentContract.UNLIMITED) {
                    this.vacancy_contracts[employmentContract.UNLIMITED] = 'unbefristeter';
                } else if (vacancy.employmentContract === employmentContract.LIMITED) {
                    this.vacancy_contracts[employmentContract.LIMITED] = 'befristeter';
                }
                this._vacancy$.next(vacancy);
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
