<header role="banner">
    <mat-toolbar color="primary" class="app-toolbar">
        <button mat-icon-button aria-label="Toggle for Sidenav" (click)="toggleSidenav('toolbar')">
            <mat-icon>menu</mat-icon>
        </button>

        <div [matMenuTriggerFor]="accountMenu" class="header-account" *ngIf="drawerMode === 'side'">
            <span [innerText]="identity.email"></span>
            <mat-icon>expand_more</mat-icon>
        </div>
    </mat-toolbar>
</header>

<mat-menu #accountMenu xPosition="before">
    <button mat-menu-item (click)="submitSignOut()">Ausloggen
        <mat-icon>exit_to_app</mat-icon>
    </button>
</mat-menu>
