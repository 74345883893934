<section class="authentication__inner">
  <header class="authentication__header">
    <div class="brand">
      <div class="brand__inner">
        <picture>
          <img
            src="/assets/images/logos/leading_companies_logo.png"
            alt="Futurenet Companies Logo"
            class="logo"
          />
        </picture>
      </div>
    </div>
  </header>
  <section class="authentication__content">
    <section class="sign-in">
      <header class="sign-in__header">
        <h2 class="mat-title">futurenet Admin</h2>
      </header>

      <form
        [formGroup]="signInForm"
        (ngSubmit)="signIn()"
        novalidate
        class="sign-in__form"
      >
        <div class="control-wrapper">
          <mat-form-field>
            <mat-label>E-Mail addresse</mat-label>
            <input
              matInput
              formControlName="identifier"
              autocomplete="email"
              autocapitalize="none"
              type="email"
              required
            />
            <mat-error *ngIf="signInForm?.controls?.identifier?.hasError('required')"
              >Pflichtfeld</mat-error
            >
            <mat-error *ngIf="signInForm?.controls?.identifier?.hasError('email')"
              >Ungültige E-Mailaddresse</mat-error
            >
          </mat-form-field>
        </div>

        <div class="control-wrapper">
          <mat-form-field>
            <mat-label>Passwort</mat-label>
            <input
              matInput
              formControlName="password"
              #passwordInput
              autocomplete="current-password"
              type="password"
              required
            />
            <a
              matSuffix
              role="button"
              tabindex="-1"
              (click)="toggleInputType()"
            >
              <mat-icon>{{ passwordInputIcon }}</mat-icon>
            </a>
            <mat-error *ngIf="signInForm.controls.password.hasError('required')"
              >Pflichtfeld</mat-error
            >
          </mat-form-field>
        </div>

        <div class="actions">
          <button
            mat-flat-button
            type="submit"
            color="primary"
            [disabled]="pending"
          >
            <span>Einloggen</span>
          </button>
        </div>
      </form>
    </section>
  </section>
  <footer class="authentication__footer"></footer>
</section>
