import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {asapScheduler, Observable, scheduled, throwError} from 'rxjs';
import {ENVIRONMENT} from '@environment';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap} from 'rxjs/operators';
import { IFile } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    constructor(private readonly httpClient: HttpClient) {
    }

    public upload(formData: FormData): Observable<HttpEvent<IFile>> {
        const REQUEST_URL = `${ENVIRONMENT.api.gatewayBaseUrl}/api/upload`;

        const HTTP_HEADERS = new HttpHeaders({});
        const HTTP_PARAMS = new HttpParams({});

        return this.httpClient
            .post<IFile>(REQUEST_URL, formData, {
                withCredentials: true,
                observe: 'events',
                headers: HTTP_HEADERS,
                params: HTTP_PARAMS,
                reportProgress: true
            }).pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                exhaustMap((resp) => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }
}
