import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { asapScheduler, Observable, scheduled, throwError, iif } from 'rxjs';
import { ENVIRONMENT } from '@environment';
import { catchError, debounceTime, distinctUntilChanged, exhaustMap, timeout, map, tap } from 'rxjs/operators';
import { FromApi, IAccount, IJob, IJobSlice, Paginated } from '@core/interfaces';
import * as qs from 'qs';
import { ID } from '../interfaces/snowflake.interface';
interface SliceOptions {
    pagination?: {
        page?: number
        pageSize?: number
    }
    queryParams?: {
        [data: string]: string | string[]
    }
    sort?: string,
    filter?: string
}

@Injectable({
    providedIn: 'root'
})
export class JobService {
    private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api/vacancies`;
    private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;
    private readonly debounceTime: number = 1000;

    constructor(private readonly httpClient: HttpClient) {
    }

    public slice(options?: SliceOptions): Observable<IJobSlice> {
        const REQUEST_URL = this.endpoint;

        const HTTP_PARAMS = new HttpParams({
            fromString: qs.stringify({
                sort: options?.sort,
                populate: '*',
                search: options?.filter,
                pagination: {
                    page: options?.pagination?.page ?? 1,
                    pageSize: options?.pagination?.pageSize ?? 10000
                }
            }, {
                encodeValuesOnly: true
            })
        });

        return this.httpClient
            .get<Paginated<any>>(REQUEST_URL, { // TODO replace any with FromApi<IJob>
                withCredentials: true,
                params: HTTP_PARAMS
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
                map(value => {
                    return {
                        meta: value.meta,
                        data: value.data.map(c => ({
                            ...c.attributes,
                            id: c.id,
                            company: {
                                ...c.attributes.company.data.attributes,
                                id: c.attributes.company.data.id
                            },
                        }))
                    } satisfies IJobSlice
                })
            );
    }

    public get(uid: ID): Observable<IJob | null> {
        const REQUEST_URL = `${this.endpoint}/${uid}`;
        const HTTP_PARAMS = new HttpParams({
            fromString: qs.stringify({
                populate: ['locations', 'attachments', 'company.addresses', 'employmentType', 'contacts', 'contacts.user', 'kinds']
            })
        });

        return this.httpClient
            .get<{ data: any }>(REQUEST_URL, { // TODO replace any with FromApi<IJob, 'contacts' | 'employmentType' | 'kinds'>
                withCredentials: true,
                params: HTTP_PARAMS
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
                map(c => ({
                    ...c.data.attributes,
                    contacts: c.data.attributes.contacts.data.map(c => {
                        if (!c) return null
                        const user = c.attributes.user as unknown as {data:any} // TODO replace any with FromApi<IAccount>
                        return {
                            ...c.attributes,
                            email: user.data?.attributes.email,
                            id: c.id
                        }
                    }),
                    employmentType: {
                        ...c.data.attributes.employmentType.data.attributes,
                        id: c.data.attributes.employmentType.data.id
                    },
                    kinds: c.data.attributes.kinds.data.map(k => ({
                        ...k.attributes,
                        id: k.id,
                    })),
                    company: c.data.attributes.company.data?.attributes ? {
                        ...c.data.attributes.company.data.attributes,
                        id: c.data.attributes.company.data.id
                    } : null,
                    locations: c.data.attributes?.locations || [],
                    id: c.data.id
                } satisfies IJob))
            );
    }

    public modify(uid: string, payload: IJob): Observable<IJob | null> {
        const REQUEST_URL = `${this.endpoint}/${uid}`;

        return this.httpClient
            .put<IJob>(REQUEST_URL, payload, {
                withCredentials: true,
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged()
            );
    }
}
