import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {IIdentity} from '@core/interfaces';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'lca-app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent {

    @Input() identity: IIdentity;
    @Input() drawerMode: MatDrawerMode;

    @Output() toggleDrawerEvent = new EventEmitter<string>();
    @Output() submitSignOutEvent = new EventEmitter<void>();

    public toggleSidenav(element?: string): void {
        this.toggleDrawerEvent.emit(element);
    }

    public submitSignOut(): void {
        this.submitSignOutEvent.emit();
    }

}
