import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { ENVIRONMENT } from '@environment';
import {
  asapScheduler,
  Observable,
  scheduled,
  throwError,
  forkJoin,
  of,
} from 'rxjs';
import { IAccount, IAccountSlice } from '@core/interfaces';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  mergeMap,
  tap,
  timeout,
  switchMap,
  concatMap,
  map,
} from 'rxjs/operators';
import { ID } from '../interfaces/snowflake.interface';
import * as qs from 'qs';
import {
  IAccountRaw,
  IPersonalInfo,
  IPersonalInfoRaw,
} from '../interfaces/account.interface';
import { PersonalInfoService } from './personal-info.service';

interface SliceOptions {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  queryParams?: {
    [data: string]: string | string[];
  };
  sort?: string;
  filter?: string;
}

export type IUserPayload = Pick<IAccountRaw, 'status' | 'email' | 'role'> & {
  personalInfo: IPersonalInfoRaw;
  username: string;
  password: string;
};

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api/users`;
  private readonly endpointApi: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api`;
  private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;
  private readonly debounceTime: number = 1000;

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly personalInfoService: PersonalInfoService
  ) {}

  public slice<T extends IAccountSlice>(options?: SliceOptions): Observable<T> {
    const REQUEST_URL = this.endpoint;

    // const defaultSort = '-id';

    const HTTP_PARAMS = new HttpParams({
      fromString: qs.stringify(
        {
          sort: options?.sort,
          populate: '*',
          search: options?.filter,
          pagination: {
            page: options?.pagination?.page ?? 1,
            pageSize: options?.pagination?.pageSize,
          },
        },
        {
          encodeValuesOnly: true,
        }
      ),
    });

    return this.httpClient
      .get<T>(REQUEST_URL, {
        withCredentials: true,
        params: HTTP_PARAMS,
      })
      .pipe(
        debounceTime(this.debounceTime),
        timeout(this.requestTimeout),
        distinctUntilChanged()
      );
  }

  public get(id: ID): Observable<IAccount | null> {
    const REQUEST_URL = `${this.endpoint}/${id}`;

    const HTTP_PARAMS = new HttpParams({
      fromString: qs.stringify(
        {
          populate: [
            'role',
            'personalInfo',
            'personalInfo.language',
            'personalInfo.company',
          ],
        },
        {
          encodeValuesOnly: true,
        }
      ),
    });

    return this.httpClient
      .get<IAccount>(REQUEST_URL, {
        withCredentials: true,
        params: HTTP_PARAMS,
      })
      .pipe(
        debounceTime(this.debounceTime),
        timeout(this.requestTimeout),
        distinctUntilChanged(),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
  }

  public create<T extends IAccount>(payload: IUserPayload): Observable<T> {
    const REQUEST_URL = `${ENVIRONMENT.api.gatewayBaseUrl}/api/auth/registerEmployee`;
    const HTTP_PARAMS = new HttpParams({ fromObject: { populate: '*' } });

    return this.httpClient.post<T>(
      REQUEST_URL,
      {
        ...payload,
        ...payload.personalInfo,
      },
      {
        withCredentials: true,
      }
    );
  }

  public modify<T extends IAccount>(
    uid: ID,
    payload: IUserPayload
  ): Observable<T> {
    const REQUEST_URL = `${this.endpoint}/${uid}`;
    const HTTP_PARAMS = new HttpParams({
      fromObject: { populate: ['role', 'company'] },
    });

    // To prevent any weird behaviour, delete the field password completly if it is empty
    if (!payload.password) {
      delete payload.password;
    }

    return this.httpClient
      .put<T>(
        REQUEST_URL,
        {
          email: payload.email,
          status: payload.status,
          role: payload.role,
          username: payload.username,
          password: payload.password,
        } satisfies Omit<
          IAccountRaw,
          'id' | 'createdAt' | 'updatedAt' | 'confirmed' | 'tosAgreement'
        > & { password: string; username: string },
        {
          withCredentials: true,
          params: HTTP_PARAMS,
        }
      )
      .pipe(
        concatMap((account) => {
          const pInfosId = payload.personalInfo.id;
          payload.personalInfo.user = account.id;
          if (!pInfosId) {
            return this.personalInfoService
              .create(payload.personalInfo)
              .pipe(map(() => account));
          } else {
            return this.personalInfoService
              .update({
                ...payload.personalInfo,
                id: pInfosId,
              })
              .pipe(map(() => account));
          }
        }),
        timeout(this.requestTimeout)
      );
  }

  public delete<T extends boolean>(uid: ID): Observable<T> {
    const REQUEST_URL = `${this.endpoint}/${uid}`;

    return this.httpClient
      .delete<T>(
        `${ENVIRONMENT.api.gatewayBaseUrl}/v${ENVIRONMENT.api.gatewayVersion}/accounts/${uid}`,
        {
          withCredentials: true,
          observe: 'response',
          responseType: 'json',
          headers: new HttpHeaders({}),
          params: new HttpParams({}),
        }
      )
      .pipe(
        debounceTime(1000),
        timeout(ENVIRONMENT.api.readTimeout),
        distinctUntilChanged(),
        exhaustMap(
          (response: HttpResponse<any>): Observable<T> =>
            scheduled([(response.status === 204) as T], asapScheduler)
        ),
        catchError((error: unknown): Observable<never> => throwError(error))
      );
  }
}
