import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HTTP_INTERCEPTORS
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ENVIRONMENT} from '@environment';

@Injectable()
export class SecureInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if (ENVIRONMENT.production) {
            const httpsReq = request.clone({
                url: request.url.replace('http://', 'https://')
            });

            return next.handle(httpsReq);
        }
        return next.handle(request);
    }
}

export const HTTP_SECURE_INTERCEPTOR_PROVIDER = {provide: HTTP_INTERCEPTORS, useClass: SecureInterceptor, multi: true};
