import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthenticationService } from '@app/core/api';

@Component({
    selector: 'lca-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {
    private readonly destroyed$: Subject<void> = new Subject<void>();
    protected readonly drawerState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    protected readonly identity$ = this.authService.identity

    public drawerMode: MatDrawerMode;

    @ViewChild(MatSidenav) sidenav: MatSidenav;

    public constructor(
        private authService: AuthenticationService,
        private readonly breakpointObserver: BreakpointObserver
    ) {}

    public ngOnInit(): void {
        this.breakpointObserver.observe([
            Breakpoints.Handset,
            Breakpoints.Tablet
        ]).subscribe(result => {
            if (result.matches) {
                this.drawerState$.next(false);
                this.drawerMode = 'over';
            } else {
                this.drawerState$.next(true);
                this.drawerMode = 'side';
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public signOut(): void {
        this.authService.invalidate()
    }

    public toggleDrawer(element?: string): void {
        if (this.drawerMode === 'side') {
            if (element === 'toolbar') {
                this.sidenav.toggle().then(null);
            }
        } else if (this.drawerMode === 'over') {
            this.sidenav.toggle().then(null);
        }
    }

}
