import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private snackBar: MatSnackBar) {
    }

    private config: MatSnackBarConfig = {
        duration: 5000,
        verticalPosition: 'bottom',
        horizontalPosition: 'left'
    };

    public error(message: string, action?: string): void {
        this.snackBar.open(message, action, this.config);
        this.config.panelClass = ['notification'];
    }
}
