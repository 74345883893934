import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CompanyListingComponent} from './features/company-listing/company-listing.component';
import {CompanyFormComponent} from './features/company-form/company-form.component';
import {CompanyResolver} from '@core/resolver';
import { IndustryResolver } from '@app/core/resolver/industry.resolver';

export const COMPANY_ROUTES = {
    MODULE: 'companies',
    ROOT: '',
    CREATE: 'create',
    MODIFY: ':id'
};

const ROUTES: Routes = [
    {
        path: COMPANY_ROUTES.ROOT,
        component: CompanyListingComponent
    },
    {
        path: COMPANY_ROUTES.CREATE,
        component: CompanyFormComponent,
        resolve: {
            industries: IndustryResolver
        }
    },
    {
        path: COMPANY_ROUTES.MODIFY,
        component: CompanyFormComponent,
        resolve: {
            company: CompanyResolver,
            industries: IndustryResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class CompaniesRoutingModule {
}
