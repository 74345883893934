import {IFile} from '@core/interfaces/file.interface';
import {IAccount, IPersonalInfo} from '@core/interfaces/account.interface';
import { ID } from './snowflake.interface';
import { Paginated } from '.';
import { IEmployementType } from './employement-type.interface';
import { IWorkExperience } from './work-experience.interface';
import { IDegree } from './degrees.interface';

export interface LanguageLevels {
    id: ID,
    name: string,
    level: number
}

export interface LanguageInfo {
    id: ID
    name: string
    code: string
}

export interface LeveledLanguage {
    language: LanguageInfo
    level: LanguageLevels
}

export interface IApplicationBase {
    id: ID
    begin_date: Date
    end_date: Date
    date_in_consultation: boolean
    workExperience: IWorkExperience
    contacts: Array<IApplicationContact>
    languages: Array<LeveledLanguage>
}

export enum IDegreesType {
    DEGREE= 'DEGREE',
    STUDYING = 'STUDYING',
}

export interface IApplicationContact {
    givenName: string
    familyName: string
    email: string
    phoneNumber: string
}

export interface IDegrees {
    type: IDegreesType,
    degrees: Array<IDegree>
}

export type IApplication = IApplicationBase & {
    lookingFors: Array<IEmployementType>
    personalInfo: IPersonalInfo
    portrait: IFile
    resume: IFile
    certificates: Array<IFile>
    author: {
        email: string
    } | {
        user: IAccount
    }
    degrees?: IDegrees
}

export type IApplicationRaw = IApplicationBase & {
    looking_for: Array<ID>
    personalInfo: ID
    portrait: ID
    resume: ID
    certificates: Array<ID>
    author: {
        email: string
    } | {
        user: ID
    }
    degrees: {
        type: IDegreesType,
        degrees: Array<ID>
    }
}

export type IApplicationSlice = Paginated<IApplication>;

export interface ILanguage {
    id: ID
    name: string;
    code: string;
}
