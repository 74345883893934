import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AUTHORIZATION_INTERCEPTOR_PROVIDER, HTTP_HEADER_INTERCEPTOR_PROVIDER, HTTP_SECURE_INTERCEPTOR_PROVIDER} from '@core/interceptors';
import {ConfirmationComponent} from '@core/components/confirmation/confirmation.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [ConfirmationComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        MatSnackBarModule,
        MatDialogModule,
        MatButtonModule
    ],
    providers: [
        AUTHORIZATION_INTERCEPTOR_PROVIDER,
        HTTP_HEADER_INTERCEPTOR_PROVIDER,
        HTTP_SECURE_INTERCEPTOR_PROVIDER
    ]
})
export class CoreModule {
    constructor() {
    }
}
