import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HTTP_INTERCEPTORS
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<unknown> | HttpUserEvent<unknown>> {
        let headers = request.headers;

        if (!(request.body instanceof FormData) && !headers.has('Content-Type')) {
            headers = headers.set('Content-Type', 'application/json');
        }

        if (!headers.has('Accept')) {
            headers = headers.set('Accept', 'application/json');
        }

        if (!headers.has('X-Requested-With')) {
            headers = headers.set('X-Requested-With', 'XMLHttpRequest');
        }

        const r = request.clone({headers});
        return next.handle(r);
    }
}

export const HTTP_HEADER_INTERCEPTOR_PROVIDER = {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true};
