import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FileUploadComponent } from '@shared/components';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
// import {NgmatTableQueryReflectorModule} from '@nghacks/ngmat-table-query-reflector';
import { AccountStatusPipe, RolePipe } from '@shared/pipes';
import { HotToastModule } from '@ngneat/hot-toast';


@NgModule({
    declarations: [FileUploadComponent, AccountStatusPipe, RolePipe],
    imports: [
        CommonModule,
        MatButtonModule,
        HotToastModule.forRoot({
            position: 'bottom-left'
        }),
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDividerModule,
        // NgmatTableQueryReflectorModule,
        FileUploadComponent,
        AccountStatusPipe,
        HotToastModule
    ]
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
