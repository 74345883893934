import { inject } from '@angular/core';
import { Router, CanActivateFn, CanActivateChildFn } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthenticationService } from '@core/api/authentication.service';
import { AUTH_ROUTES } from '@app/modules/auth/auth-routing.module';

export const authGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const authService = inject(AuthenticationService)
    const router = inject(Router)
    const routeUrl = state.url

    return authService.hasIdentity
        .pipe(
            take(1),
            map(identity => {
                if (identity && route.data.role?.indexOf(identity.role) >= 0) {
                    return true
                }

                authService.invalidate()

                return router.createUrlTree([AUTH_ROUTES.MODULE, AUTH_ROUTES.SIGN_IN], {
                    queryParams: {
                        redirect: routeUrl
                    }
                })

            })
        )

}