import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environment';
import { Observable, debounceTime, timeout, distinctUntilChanged, map, filter, tap } from 'rxjs';
import { IRole } from '../interfaces/identity.interface';

@Injectable({ providedIn: 'root' })
export class RoleService {
    private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api/users-permissions/roles`;
    private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;
    private readonly debounceTime: number = 1000;

    public constructor(private readonly httpClient: HttpClient) { }

    public list(): Observable<IRole[]> {
        return this.httpClient
            .get<{ roles: IRole[] }>(this.endpoint, {
                withCredentials: true
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
                map(value => value.roles
                    // TODO: find a cleaner way to skip these values
                    .filter(v => !['authenticated', 'public'].includes(v.type))
                )
            )
    }

}