<form [formGroup]="accountForm" class="create-form">

    <span class="mat-headline" role="heading">Account</span>

    <div *ngIf="account.status === 'NEWLY' && account.role === 'applicant'" style="margin-bottom: 24px;">
        <button mat-flat-button color="accent" (click)="activateApplicantProfile()">Bewerberprofil freischalten</button>
    </div>

    <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput formControlName="givenName" type="text" name="givenName" autocomplete="off" required>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input matInput formControlName="familyName" type="text" name="familyName" autocomplete="off" required>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Sprache</mat-label>
        <mat-select formControlName="language" disableOptionCentering required>
            <mat-option *ngFor="let language of languageSlice" [value]="language.id">{{language.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input matInput formControlName="email" type="email" required name="email" autocomplete="off">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Rolle</mat-label>
        <mat-select formControlName="role" disableOptionCentering required (valueChange)="validateRole($event)">
            <mat-option *ngFor="let role of roleSlice" [value]="role.id">{{role?.name?.toUpperCase()}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" disableOptionCentering required [value]="account.role?.type">
            <mat-option *ngFor="let status of statusSlice" [value]="status.value">{{status.key}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Unternehmen</mat-label>
        <mat-select formControlName="company" disableOptionCentering>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Telefonnummer</mat-label>
        <input matInput formControlName="phoneNumber" type="tel" name="phoneNumber" autocomplete="off">
    </mat-form-field>

    <!-- <mat-form-field>
        <mat-label>Passwort</mat-label>
        <input matInput formControlName="password" name="password" type="password" autocomplete="one-time-code">
    </mat-form-field> -->

    <div class="action-row">
        <button mat-stroked-button routerLink="/accounts" type="button">Abbrechen</button>
        <button mat-flat-button (click)="submit()" color="primary" type="submit" [disabled]="accountForm.invalid">Speichern</button>
    </div>
</form>