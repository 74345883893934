import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, catchError, tap } from 'rxjs';
import { ICompany, IIndustry } from '@core/interfaces';
import { ID } from '../interfaces/snowflake.interface';
import { COMPANY_ROUTES } from '@app/modules/companies/companies-routing.module';
import { IndustryService } from '@core/api/industry.service';

@Injectable({
    providedIn: 'root'
})
export class IndustryResolver {
    constructor(private industryService: IndustryService,
        private router: Router) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IIndustry[]> | Promise<IIndustry[]> | any {
        return this.industryService.list();
    }

}
