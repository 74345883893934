import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { UploadService } from '@core/api';
import { ID } from '@app/core/interfaces/snowflake.interface';

@Component({
    selector: 'lca-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit, OnDestroy {
    private readonly destroyed$: Subject<void> = new Subject<void>();
    public readonly hide$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() fieldName: string;
    @Input() acceptMimeType: string;
    @Input() buttonText: string;
    @Input() hideBtnAfterUpload: boolean;
    @Input() disabled: boolean;
    @Output() fileUploadedEvent = new EventEmitter();
    @Output() fileRemoveEvent = new EventEmitter();

    constructor(private readonly uploadService: UploadService,
        private readonly cdRef: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.cdRef.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public onFileSelected(event: Event): void {
        const FILE: FileList | null = (event.target as HTMLInputElement).files;
        this.uploadFiles(FILE);
    }

    private uploadFiles(files: FileList | null): void {
        if (files != null) {
            if (files.length < 1) {
                return;
            }
            Array.from(files).forEach(file => {
                const FORM_DATA = new FormData();
                FORM_DATA.append('files', file, file.name);
                this.uploadService
                    .upload(FORM_DATA)
                    .pipe(
                        take(1)
                    ).subscribe({
                        next: (ev: HttpEvent<any>): void => {
                            if (this.hideBtnAfterUpload) {
                                this.hide$.next(true);
                            }
                            this.fileUploadedEvent.emit(ev);
                        },
                        error: (error: HttpErrorResponse) => {
                            console.error(error);
                        },
                        complete: () => {
                            console.log('upload completed');
                        }
                    });
            });
        }
    }

}
