import { ICompany, Paginated } from ".";
import { ILanguage } from "./application.interface";
import { IRole } from "./identity.interface";
import { ID } from "./snowflake.interface";

export enum UserStatus {
    NEWLY = "NEWLY",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    BLOCKED = "BLOCKED",
    DELETED = "DELETED"
}
export interface IPersonalInfoBase {
    id?: ID,
    givenName: string
    familyName: string
    phoneNumber?: string
}

export type IPersonalInfo = IPersonalInfoBase & {
    language?: ILanguage
    user?: IAccount
    company?: ICompany
}

export type IPersonalInfoRaw = IPersonalInfoBase & {
    language?: ID
    user?: ID
    company?: ID
}

export interface IAccountBase {
    id: ID
    confirmed: boolean
    createdAt: string
    updatedAt: string
    email: string
    status: UserStatus,
    tosAgreement: boolean
}

export type IAccount = IAccountBase & {
    role?: IRole
    personalInfo?: IPersonalInfo
}

export type IAccountRaw = IAccountBase & {
    role?: ID
    personalInfo?: ID
}

export type IAccountSlice = Paginated<IAccount>