import {enableProdMode, ViewEncapsulation} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {ENVIRONMENT} from '@environment';
import {AppModule} from './app/app.module';

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic().bootstrapModule(AppModule, {
        defaultEncapsulation: ViewEncapsulation.None
    })
        .catch(err => console.error(err));
});

if (ENVIRONMENT.production) {
    enableProdMode();
}
