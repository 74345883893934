import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '@environment';
import { debounceTime, distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
import { FromApi, ICompany, ICompanySlice, Paginated } from '@core/interfaces';
import * as qs from 'qs'
import { ID } from '../interfaces/snowflake.interface';
import { RecursivePartial } from '../utils/recursive-partials';
import { ICompanyRaw } from '../interfaces/company.interface';

interface SliceOptions {
    pagination?: {
        page?: number
        pageSize?: number
    }
    queryParams?: {
        [data: string]: string | string[]
    }
    sort?: string,
    filter?: string
}

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api/companies`;
    private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;
    private readonly debounceTime: number = 1000;

    constructor(private readonly httpClient: HttpClient) {
    }

    public slice(options?: SliceOptions): Observable<ICompanySlice> {
        const REQUEST_URL = this.endpoint;

        const HTTP_PARAMS = new HttpParams({
            fromString: qs.stringify({
                sort: options?.sort,
                search: options?.filter,
                pagination: {
                    page: options?.pagination?.page ?? 1,
                    pageSize: options?.pagination?.pageSize ?? 10000
                }
            }, {
                encodeValuesOnly: true
            })
        });

        return this.httpClient
            .get<Paginated<FromApi<ICompany>>>(REQUEST_URL, {
                withCredentials: true,
                params: HTTP_PARAMS
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
                map(value => {
                    return {
                        meta: value.meta,
                        data: value.data.map(c => ({
                            ...c.attributes,
                            id: c.id,
                        }))
                    } satisfies ICompanySlice
                }),
            );
    }

    public get(id: ID): Observable<ICompany | null> {
        const REQUEST_URL = `${this.endpoint}/${id}`;

        const HTTP_PARAMS = new HttpParams({
            fromString: qs.stringify({
                populate: '*'
            })
        });

        return this.httpClient
            .get<{ data: FromApi<ICompany, 'logo' | 'photo' | 'industry'> }>(REQUEST_URL, {
                withCredentials: true,
                params: HTTP_PARAMS
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
                map(c => ({
                    ...c.data.attributes,
                    industry: c.data.attributes.industry?.data?.attributes ? {
                        ...c.data.attributes.industry.data.attributes,
                        id: c.data.attributes.industry.data.id
                    } : null,
                    photo: c.data.attributes.photo?.data?.attributes ? {
                        ...c.data.attributes.photo.data.attributes,
                        id: c.data.attributes.photo.data.id,
                    } : null,
                    logo: {
                        ...c.data.attributes.logo.data.attributes,
                        id: c.data.attributes.logo.data.id,
                    },
                    id: c.data.id,
                } satisfies ICompany))
            );
    }

    public create<T extends ICompany>(payload: RecursivePartial<ICompanyRaw>): Observable<T> {
        const REQUEST_URL = this.endpoint;

        return this.httpClient
            .post<T>(REQUEST_URL, {data: payload}, {
                withCredentials: true,
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged()
            );
    }

    public modify<T extends ICompany>(uid: ID, payload: RecursivePartial<ICompanyRaw>): Observable<T> {
        const REQUEST_URL = `${this.endpoint}/${uid}`;

        return this.httpClient
            .put<T>(REQUEST_URL, {data: payload}, {
                withCredentials: true,
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
            );
    }
}
