import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { IApplication } from '@core/interfaces';
import { ApplicationService } from '@core/api';
import { catchError, tap } from 'rxjs/operators';
import { ID } from '../interfaces/snowflake.interface';

@Injectable({
    providedIn: 'root'
})
export class ApplicationResolver {

    constructor(private applicationService: ApplicationService,
        private router: Router) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IApplication | null> {
        let id: ID;

        if (!route.paramMap.get('id')) {
            if (route.parent != null) {
                if (!route.parent.paramMap.get('id')) {
                    void this.router.navigate(['/applications']);
                    return EMPTY;
                }

                id = parseInt(route.parent.paramMap.get('id'));
            }
        } else {
            id = parseInt(route.paramMap.get('id'));
        }

        if (isNaN(id)) {
            void this.router.navigate(['/applications'])
            return
        }

        return this.applicationService
            .get(id)
            .pipe(
                tap(application => {
                    if (!application?.id) {
                        throw new Error('Invalid Application')
                    }
                }),
                catchError((err) => {
                    console.error(err)
                    void this.router.navigate(['/applications']);
                    return EMPTY;
                })
            );
    }
}
