import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, catchError, tap } from 'rxjs';
import { CompanyService } from '@core/api';
import { ICompany } from '@core/interfaces';
import { ID } from '../interfaces/snowflake.interface';
import { COMPANY_ROUTES } from '@app/modules/companies/companies-routing.module';

@Injectable({
    providedIn: 'root'
})
export class CompanyResolver {
    constructor(private companyService: CompanyService,
        private router: Router) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICompany> | Promise<ICompany> | any {
        let id: ID;

        if (!route.paramMap.get('id')) {
            if (route.parent != null) {
                if (!route.parent.paramMap.get('id')) {
                    void this.router.navigate(['/companies']);
                    return;
                }

                id = parseInt(route.parent.paramMap.get('id'))
            }
        } else {
            id = parseInt(route.paramMap.get('id'));
        }

        if (isNaN(id)) {
            void this.router.navigate(['/companies'])
            return
        }

        return this.companyService
            .get(id)
            .pipe(
                tap(company => {
                    if (!company?.id) {
                        throw new Error('Invalid Company')
                    }
                }),
                catchError(() => {
                    void this.router.navigate(['/companies']);
                    return EMPTY;
                })
            );
    }

}
