import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ApplicationListingComponent} from './features/application-listing/application-listing.component';
import {ApplicationViewComponent} from './features/application-view/application-view.component';
import {ApplicationResolver} from '@core/resolver';

export const APPLICATION_ROUTES = {
    MODULE: 'applications',
    ROOT: '',
    LISTING: '',
    VIEW: ':id'
};

const ROUTES: Routes = [
    {
        path: APPLICATION_ROUTES.LISTING,
        component: ApplicationListingComponent
    },
    {
        path: APPLICATION_ROUTES.VIEW,
        component: ApplicationViewComponent,
        resolve: {
            application: ApplicationResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class ApplicationsRoutingModule {
}
