<form [formGroup]="companyForm">
  <lca-company-form-header
    [companyForm]="companyForm"
    [statusSlice]="statusSlice"
    [loading$]="loading$"
    (submit)="submit()"
  ></lca-company-form-header>

  <mat-tab-group dynamicHeight>
    <mat-tab
      label="Stammdaten"
      [labelClass]="isValidTab('core') ? companyForm.status : ''"
    >
      <lca-company-core-form
        [companyForm]="companyForm"
        [industryOptions]="industryOptions"
      />
    </mat-tab>
    <mat-tab
      label="Anschrift"
      [labelClass]="isValidTab('address') ? companyForm.status : ''"
    >
      <section class="form-wrapper">
        <div formArrayName="addresses">
          <div *ngIf="locationsFormArray.length < 1">mind. 1 Anschrift</div>
          <div
            *ngFor="let _ of locationsFormArray.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="location-inputs">
              <mat-form-field>
                <mat-label>Straße</mat-label>
                <input matInput formControlName="street" type="text" required />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Nummer</mat-label>
                <input matInput formControlName="streetNumber" type="text" />
              </mat-form-field>
            </div>

            <div class="location-inputs">
              <mat-form-field>
                <mat-label>Postleitzahl</mat-label>
                <input matInput formControlName="postalCode" type="text" required />
              </mat-form-field>

              {{ _.getError('required') }}

              <mat-form-field>
                <mat-label>Stadt</mat-label>
                <input matInput formControlName="city" type="text" required />
              </mat-form-field>
            </div>

            <div class="location-inputs">
              <mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput formControlName="country" type="text" required />
              </mat-form-field>

              <div></div>
              <!-- Spacer: scss is using last-child to add margin to the right input-->
            </div>

            <div class="location-inputs__interactable">
              <mat-checkbox formControlName="headquarter">Hauptanschrift?</mat-checkbox>
              <button
                mat-icon-button
                (click)="removeLocation(i)"
                type="button"
                color="warn"
                [disabled]="locationsFormArray.controls.length === 1"
                title="Anschrift entfernen"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div>
          <button mat-stroked-button (click)="addAddress()" type="button">
            Anschrift hinzufügen
          </button>
        </div>
      </section>
    </mat-tab>
    <mat-tab label="Medien">
      <section class="form-wrapper">
        <section class="file-upload-container">
          <h1 class="mat-headline">Logo</h1>
          <ng-container *ngIf="logo$ | async as file">
            <div class="file-preview-container">
              <img
                [src]="fileServerBase + file?.url"
                class="logo-preview"
                [alt]="file?.name"
              />
              <div class="file-name-container">
                <span class="file-name">{{ file?.name }}</span>
              </div>
            </div>
          </ng-container>
          <lca-file-upload
            [acceptMimeType]="'*/*'"
            [fieldName]="'logo'"
            [buttonText]="(logo$ | async) ? 'Logo ändern' : 'Logo hochladen'"
            [hideBtnAfterUpload]="true"
            (fileUploadedEvent)="addLogo($event.body)"
          >
          </lca-file-upload>
        </section>
      </section>
    </mat-tab>
  </mat-tab-group>
</form>
