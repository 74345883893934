import {Pipe, PipeTransform} from '@angular/core';
import {AccountStatus} from '@core/enums';

@Pipe({
  name: 'accountStatus'
})
export class AccountStatusPipe implements PipeTransform {

  private readonly status: Array<{ key: string; value: string }> = Object.keys(AccountStatus).map((key: string): { key: string, value: string } => {
    return {
      key,
      value: AccountStatus[key as keyof typeof AccountStatus]
    };
  });

  public transform(value: string): string {
    return this.status.find(v => v.value === value)?.key as string;
  }

}
