import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/api';
import { IAuthenticationParams } from '@app/core/interfaces';
import { HotToastService } from '@ngneat/hot-toast';
import { HttpErrorResponse } from '@angular/common/http';

type SignInForm = {
  [K in keyof IAuthenticationParams]: FormControl<IAuthenticationParams[K]>;
};

@Component({
  selector: 'lca-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject<void>();

  public signInForm: FormGroup<SignInForm>;
  public passwordInputIcon = 'visibility';
  protected pending = false;

  @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;

  public constructor(
    private authService: AuthenticationService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
    private toast: HotToastService
  ) {
    this.signInForm = this.formBuilder.group({
      identifier: this.formBuilder.control(null, [
        Validators.required,
        Validators.email,
      ]),
      password: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
    });

    this.authService.isAuthenticated
      .pipe(
        distinctUntilChanged(),
        filter((authenticated) => authenticated),
        mergeMap(() => this.route.queryParams),
        map((params) => params['redirect'] ?? '/')
      )
      .subscribe((nextUrl) => {
        void this.router.navigateByUrl(nextUrl);
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public signIn(): void {
    if (this.signInForm.invalid) {
      return;
    }

    this.cdRef.markForCheck();
    const payload = this.signInForm.getRawValue();
    this.pending = true;
    this.authService.authenticate(payload).subscribe({
      next: (i) => {
        this.pending = false;
        if (!i) {
          this.toast.error('Invalid identifier or password');
        }
        this.cdRef.markForCheck();
      },
      error: (error) => {
        this.pending = false;
        this.cdRef.markForCheck();

        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              return this.toast.error(error.error.error.message);
            case 403:
              return this.toast.error('Forbidden access');
            default:
              this.toast.error('Unknown error');
              console.error(error);
          }
        } else {
          this.toast.error('Unknown error');
          console.error(error);
        }
      },
    });
  }

  public toggleInputType(): void {
    const input = this.passwordInput.nativeElement;
    if (input.type === 'password') {
      input.type = 'text';
      this.passwordInputIcon = 'visibility_off';
    } else {
      input.type = 'password';
      this.passwordInputIcon = 'visibility';
    }
  }
}
