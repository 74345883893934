import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {IJob} from '@core/interfaces';
import {JobService} from '@core/api';
import {catchError, tap} from 'rxjs/operators';
import { ID } from '../interfaces/snowflake.interface';

@Injectable({
    providedIn: 'root'
})
export class JobResolver  {

    constructor(private jobService: JobService,
                private router: Router) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IJob> | Promise<IJob> | any {
        let id: ID;

        if (!route.paramMap.get('id')) {
            if (route.parent != null) {
                if (!route.parent.paramMap.get('id')) {
                    this.router.navigate(['/jobs']).then();
                    return;
                }

                id = parseInt(route.parent.paramMap.get('id'));
            }
        } else {
            id = parseInt(route.paramMap.get('id'));
        }

        if (isNaN(id)) {
            void this.router.navigate(['/jobs'])
            return
        }

        return this.jobService
            .get(id)
            .pipe(
                tap(job => {
                    if (!job?.id) {
                        throw new Error('Invalid Job')
                    }
                }),
                catchError((err) => {
                    void this.router.navigate(['/jobs']);
                    return EMPTY;
                })
            );
    }
}
