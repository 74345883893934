import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AUTH_ROUTES } from '@modules/auth/auth-routing.module';
import { HOME_ROUTES } from '@modules/home/home-routing.module';
import { authGuard, guestGuard } from '@core/guards';

const ROUTES: Routes = [
    {
        path: AUTH_ROUTES.MODULE,
        loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule),
        canActivate: [guestGuard]
    },
    {
        path: HOME_ROUTES.MODULE,
        canActivate: [authGuard],
        data: {
            role: ['admin']
        },
        loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(ROUTES, {
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
            anchorScrolling: 'enabled',
            initialNavigation: 'enabledBlocking',
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
