import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {asapScheduler, forkJoin, Observable, scheduled} from 'rxjs';
import {IApplyingForOptionsSlice, IEducationSlice, IExperienceInSlice, IInCompanyTrainingSlice, IIndustrySlice, ILanguageLevelSlice, ILanguageSlice} from '@core/interfaces';
import {exhaustMap, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApplicationValueService {

    constructor(private readonly httpClient: HttpClient) {
    }

    public getCommon(): Observable<any> {
        return forkJoin([
            this.getApplyingForOptions(),
            this.getIndustries(),
            this.getInCompanyTraining(),
            this.getEducations(),
            this.getExperienceIn(),
            this.getWorkExperience(),
            this.getLanguages(),
            this.getLanguageLevels()
        ]).pipe(
            map(([
                     applyingFor,
                     industries,
                     inCompanyTraining,
                     fieldOfStudy,
                     experienceIn,
                     workExperience,
                     language,
                     languageLevel
                 ]) => {
                return {applyingFor, industries, inCompanyTraining, fieldOfStudy, experienceIn, workExperience, language, languageLevel};
            })
        );
    }

    public getApplyingForOptions<T extends IApplyingForOptionsSlice>(): Observable<T> {
        const REQUEST_URL = '/assets/data/applying_for_options.json';

        return this.httpClient
            .get<T>(REQUEST_URL)
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }

    public getIndustries<T extends IIndustrySlice>(): Observable<T> {
        const REQUEST_URL = '/assets/data/industries.json';

        return this.httpClient
            .get<T>(REQUEST_URL)
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }

    public getExperienceIn<T extends IExperienceInSlice>(): Observable<T> {
        const REQUEST_URL = '/assets/data/experience_in.json';

        return this.httpClient
            .get<T>(REQUEST_URL)
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }

    public getEducations<T extends IEducationSlice>(): Observable<T> {
        const REQUEST_URL = '/assets/data/education.json';

        return this.httpClient
            .get<T>(REQUEST_URL)
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }

    public getInCompanyTraining<T extends IInCompanyTrainingSlice>(): Observable<T> {
        const REQUEST_URL = '/assets/data/in_company_training.json';

        return this.httpClient
            .get<T>(REQUEST_URL)
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }

    public getLanguages<T extends ILanguageSlice>(): Observable<T> {
        const REQUEST_URL = '/assets/data/languages.json';

        return this.httpClient
            .get<T>(REQUEST_URL)
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }

    public getWorkExperience<T extends any>(): Observable<T> {
        const REQUEST_URL = `/assets/data/experience_in.json`;

        const HEADERS = new HttpHeaders({skip: 'true'});

        return this.httpClient
            .get<T>(REQUEST_URL, {
                headers: HEADERS
            })
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }

    public getLanguageLevels<T extends ILanguageLevelSlice>(): Observable<T> {
        const REQUEST_URL = '/assets/data/language_levels.json';

        return this.httpClient
            .get<T>(REQUEST_URL)
            .pipe(
                exhaustMap(resp => {
                    return scheduled([resp], asapScheduler);
                })
            );
    }
}
