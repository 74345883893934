import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { IApplication } from '@core/interfaces';
import { ActivatedRoute } from '@angular/router';
import { ENVIRONMENT } from '@environment';

@Component({
    selector: 'lca-application-view',
    templateUrl: './application-view.component.html',
    styleUrls: ['./application-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationViewComponent implements OnInit, OnDestroy {
    private readonly destroyed$: Subject<void> = new Subject<void>();
    protected application: IApplication;
    public readonly fileServerBase: string = ENVIRONMENT.api.fileServerBaseUrl;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.cdRef.detach();
        this.application = { ...this.route.snapshot.data.application };

        /*this.common$.pipe(
            finalize(() => {
                this.cdRef.markForCheck();
            }),
            take(1)
        ).subscribe((common) => {
            const businessesResolved: Array<string> = [];
            this.application.businesses.forEach((businessFor: string) => {
                const business = common.industries.find((option: any) => option.uid === businessFor);
                if (typeof business !== 'undefined') {
                    businessesResolved.push(business.value);
                }
            });

            const educationResolved: Array<string> = [];
            this.application.education.forEach((education: string) => {
                const edu = common.fieldOfStudy.find((option: IEducation) => option.id === education);
                if (typeof edu !== 'undefined') {
                    educationResolved.push(edu.name);
                }
            });

            const experienceInResolved: Array<ID> = [];
            this.application.experienceIn.forEach((experienceIn) => {
                const experience = common.experienceIn.find((option: IExperienceIn) => option.id === experienceIn);
                if (typeof experience !== 'undefined') {
                    experienceInResolved.push(experience.title);
                }
            });
        }, () => {
        }, () => {
            this.cdRef.reattach();
        });*/

        this.cdRef.reattach();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}
