<ng-container *ngIf="(vacancy$ | async) as vacancy">
    <div class="vacancy-view__container">
        <header class="vacancy-view__header">
            <div class="vacancy-view__header__title--container">
                <div class="vacancy-view__header__title">
                    <h1 style="text-wrap: nowrap; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%;">{{vacancy.title}}</h1>
                </div>
                <div class="vacancy-view__header__overlay"></div>
            </div>
        </header>

        <div>
            <section class="view-section locations" *ngIf="vacancy.company?.addresses?.length">
                <div class="employment-kind__list">
                    <span class="employment-kind__list__kinds">
                        {{vacancy.company?.addresses[0].street}}
                        {{vacancy.company?.addresses[0].streetNumber}}
                        {{vacancy.company?.addresses[0].postalCode}}
                        {{vacancy.company?.addresses[0].city}}
                        {{vacancy.company?.addresses[0].country}}
                    </span>
                </div>
            </section>

            <section class="view-section employment-kind">
                <div class="employment-kind__list">
                    <span *ngFor="let kind of vacancy_kinds" class="employment-kind__list__kinds">{{kind}}</span>
                </div>
            </section>
        </div>

        <mat-divider></mat-divider>

        <section class="view-section description">
            <h2>Beschreibung</h2>
            <span [innerHTML]="vacancy.description"></span>
        </section>

        <section class="view-section offerings">
            <h2>Was wir bieten</h2>
            <span [innerHTML]="vacancy.offering"></span>
        </section>

        <section class="view-section conditions">
            <h2>Konditionen</h2>
            <p>{{vacancy.workHours}}h pro Woche</p>
            <p>{{ vacancy_contracts[vacancy.employmentContract] }} Arbeitsvertrag</p>
            <p>Anstellung in {{ vacancy.employmentType.name }}</p>
        </section>

        <mat-divider></mat-divider>

        <section class="view-section contact">
            <h2>Kontaktpersonen</h2>
            <div class="contact-card--wrapper">
                <div *ngFor="let contact of vacancy.contacts" class="contact-card">
                    <div class="contact-card__name">
                        <span>{{contact.givenName}} {{contact.familyName}}</span>
                    </div>
                    <div class="contact-card__contact">
                        <p>{{contact.email}}</p>
                        <p>{{contact.phoneNumber}}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="view-section contact">
            <h2 class="mat-headline">Standorte</h2>
            <div *ngIf="!vacancy?.locations?.length">No locations</div> <!-- TODO translations -->
            <div class="contact-card--wrapper">
                <div *ngFor="let location of vacancy.locations" class="contact-card">
                    <div>
                        <span>{{location?.street}} {{location?.streetNumber}}</span>
                    </div>
                    <div>
                        <span>{{location?.postalCode}} {{location?.city}}</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>