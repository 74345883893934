<div class="table-container">
  <header class="table-header">
    <mat-progress-bar
      [ngStyle]="{ visibility: (loading$ | async) ? 'visible' : 'hidden' }"
      mode="indeterminate"
    ></mat-progress-bar>
    <h1 class="mat-headline" role="heading">Bewerberprofile</h1>
    <div class="table-toolbar" role="toolbar">
      <div class="table-filter">
        <mat-form-field role="searchbox">
          <mat-label>Suchen</mat-label>
          <input
            matInput
            (keyup)="filter($event)"
            autocomplete="off"
            autocapitalize="off"
          />
        </mat-form-field>
      </div>

      <div class="table-action">
        <button
          mat-stroked-button
          (click)="reload()"
          aria-label="Aktualisieren List"
        >
          <mat-icon>autorenew</mat-icon>
          Aktualisieren
        </button>
      </div>
    </div>
  </header>

  <mat-table
    [dataSource]="dataSource"
    matSort
    role="table"
    [trackBy]="trackByUid"
    NgMatTableQueryReflector
  >
    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef>Nutzer</mat-header-cell>
      <mat-cell *matCellDef="let e">
        <span>
          {{ e.personalInfo?.givenName }}
          {{
            !(e.personalInfo?.givenName && e.personalInfo?.familyName)
              ? 'Applicant, Anonymous'
              : ' '
          }}
          {{ e.personalInfo?.familyName }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let e">
        <span>{{ e.personalInfo?.status || '-' }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        aria-label="Aktionen"
      ></mat-header-cell>
      <mat-cell *matCellDef="let e">
        <button
          mat-icon-button
          type="button"
          color="warn"
          (click)="openDeleteDialog(e.personalInfo.id)"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <a mat-icon-button [routerLink]="[e.id]">
          <mat-icon>open_in_new</mat-icon>
        </a>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
    <div *matNoDataRow>
      <div class="no-data-row">Keine Einträge gefunden.</div>
    </div>
  </mat-table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    showFirstLastButtons
  ></mat-paginator>
</div>
