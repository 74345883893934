import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { IAccount, ICompany, ICompanySlice, ILanguage } from '@core/interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountRole, AccountStatus } from '@core/enums';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NotificationService } from '@core/services';
import { AccountService, ApplicationService, AuthenticationService, CompanyService } from '@core/api';
import { ObjectExt } from '@core/utils';
import { IPersonalInfo } from '@app/core/interfaces/account.interface';
import { ID } from '@app/core/interfaces/snowflake.interface';
import { IIdentity, IRole } from '@app/core/interfaces/identity.interface';
import { LanguageService } from '@app/core/api/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IUserPayload } from '@app/core/api/account.service';
import { RoleService } from '@app/core/api/role.service';

type AccountFields =
    Pick<IPersonalInfo, 'familyName' | 'givenName' | 'phoneNumber'> &
    Pick<IAccount, 'status' | 'email'> &
    {
        language: ID
        password: string
        company?: ID,
        role?: IRole['id']
    }

type AccountForm = {
    [K in keyof AccountFields]: FormControl<AccountFields[K]>
}

@Component({
    selector: 'lca-account-form',
    templateUrl: './account-form.component.html',
    styleUrls: ['./account-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountFormComponent implements OnInit, OnDestroy {
    private readonly destroyed$: Subject<void> = new Subject<void>();
    private readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly submitted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public account: any; // TODO replace any with IAccount
    public accountForm: FormGroup<any>; // TODO replace any with AccountForm

    public statusSlice: Array<{ key: string, value: string }> = [];
    public roleSlice: Array<any> = []; // TODO replace any with IRole
    public languageSlice: Array<ILanguage> = [];

    public companies: Array<ICompany> = [];

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        public readonly location: Location,
        private readonly notifier: NotificationService,
        private readonly applicationService: ApplicationService,
        private readonly accountService: AccountService,
        private readonly companyService: CompanyService,
        private readonly languageService: LanguageService,
        private readonly roleService: RoleService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly authService: AuthenticationService
    ) {
        this.account = { ...this.route.snapshot.data.account };

        this.accountForm = this.formBuilder.group<any>({ // TODO replace any with AccountForm
            givenName: new FormControl<string>(null, [Validators.required, Validators.maxLength(128)]),
            familyName: new FormControl(null, [Validators.required, Validators.maxLength(128)]),
            language: new FormControl(null, []),
            email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(128)]),
            role: new FormControl(3, [Validators.required]),
            status: new FormControl('NEWLY', [Validators.required]),
            phoneNumber: new FormControl(null, { updateOn: `change` }),
            // password: new FormControl(null, { updateOn: `change`, validators: [Validators.minLength(8)] }),
            company: new FormControl(null)
        });


        this.authService.identity.subscribe(identity => {
            if (this.account.id === identity.id) {
                this.accountForm.controls.role.disable()
            }
        })
    }

    public ngOnInit(): void {
        forkJoin([
            this.companyService.slice(),
            this.languageService.list(),
            this.roleService.list(),
        ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: ([company, languages, roles]: [ICompanySlice, ILanguage[], IRole[]]) => {
                    this.companies = company.data.filter(e => e.status === 'ACTIVE');
                    this.statusSlice = Object.keys(AccountStatus).map((key: string): { key: string, value: string } => {
                        return {
                            key,
                            value: AccountStatus[key as keyof typeof AccountStatus]
                        };
                    });

                    this.roleSlice = roles

                    this.languageSlice = languages
                },
                complete: () => {
                    if (!ObjectExt.isEmptyObject(this.account)) {
                        this.populate({ ...this.account });
                    }
                    this.changeDetector.detectChanges()
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public validateRole(roleID: number): void {
        const role = this.roleSlice.find(r => r.id == roleID)
        if (!role)
            return;

        if (role.name === 'hr-admin' || role.name == 'hr') {
            this.accountForm.controls.company.enable();
            this.accountForm.controls.company.setValidators([Validators.required]);
        } else {
            this.accountForm.controls.company.setValue(undefined);
            this.accountForm.controls.company.disable();
            this.accountForm.controls.company.setValidators(null);
        }
        this.accountForm.controls.company.updateValueAndValidity()
        this.changeDetector.detectChanges();
    }

    private populate(values: any): void { // TODO replace any with IAccount
        this.validateRole(values.role.id);
        this.accountForm.reset();

        this.accountForm.patchValue({
            givenName: values.personalInfo?.givenName,
            familyName: values.personalInfo?.familyName,
            email: values.email,
            company: values.personalInfo?.company?.id,
            language: values.personalInfo?.language?.id,
            phoneNumber: values.personalInfo?.phoneNumber,
            role: values.role?.id,
            status: values.personalInfo?.status
        });
    }

    public submit(): void {
        if (this.accountForm.invalid) {
            this.notifier.error('Bitte Eingaben überprüfen.');
            return;
        }

        this.loading$.next(true);

        const VALUES: AccountFields = this.accountForm.getRawValue();

        const payload: any = { // TODO replace any with IUserPayload
            email: VALUES.email,
            role: VALUES.role,
            personalInfo: {
                status: VALUES.status,
                id: this.account.personalInfo?.id,
                familyName: VALUES.familyName,
                givenName: VALUES.givenName,
                phoneNumber: VALUES.phoneNumber,
                language: VALUES.language,
                company: VALUES.company
            },
            // password: VALUES.password,
            // TODO: find a way in strapi to auto-fill this field
            username: VALUES.email
        }

        let crudAction: Observable<IAccount | boolean>

        if (this.account.id) {
            crudAction = this.accountService.modify(this.account.id, payload);
        } else {
            crudAction = this.accountService.create(payload)
        }

        crudAction
            .pipe(
                finalize(()=> {
                    this.loading$.next(false)
                    this.submitted$.next(false)
                })
            )
            .subscribe({
                next: (resp: IAccount | boolean): IAccount | boolean => {
                    void this.router.navigate(['/accounts'])
                    return resp
                },
                error: (error: HttpErrorResponse) => {
                    return error
                }
            })
    }

    public activateApplicantProfile(): void {
        this.applicationService
            .activateProfile(this.account.id)
            .pipe(take(1))
            .subscribe({
                next: (resp) => {
                    if (resp) {
                        this.notifier.error(`Profil freigeschaltet`);
                    } else {
                        this.notifier.error(`Profile konnte nicht freigeschaltet werden`);
                    }
                },
                error: (err) => {
                    this.notifier.error(`Profile konnte nicht freigeschaltet werden ${err.status}`);
                }
            });
    }

}
