import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, race, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { IJob, IJobSlice } from '@core/interfaces';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { JobService } from '@core/api';
import { delay, filter, finalize, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ObjectExt } from '@core/utils';
import { ActivatedRoute, Params } from '@angular/router';
import { ID } from '@app/core/interfaces/snowflake.interface';

@Component({
    selector: 'lca-job-listing',
    templateUrl: './job-listing.component.html',
    styleUrls: ['./job-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobListingComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly destroyed$: Subject<void> = new Subject<void>();
    public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public dataSource: MatTableDataSource<IJob> = new MatTableDataSource<IJob>([]);
    public columns: string[] = ['title', 'company', 'status', 'actions'];
    public pageSize = 10;
    public pageSizeOptions: number[] = [10, 50, 100];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private readonly jobService: JobService,
        private readonly activatedRoute: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.load();
    }

    public ngAfterViewInit(): void {
        race(
            this.loading$.pipe(
                filter(v => !v),
                withLatestFrom(
                    this.activatedRoute.queryParams.pipe(
                        filter(v => !ObjectExt.isEmptyObject(v)),
                        take(1)
                    )
                ),
                delay(100),
                switchMap(([_, params]: [boolean, Params]): Observable<never> => {
                    if (this.dataSource.paginator instanceof MatPaginator) {
                        this.dataSource.paginator.pageSize = params.page_size;
                        for (let i = 0; i < params.page_index; i++) {
                            this.dataSource.paginator.nextPage();
                        }
                    }
                    return EMPTY;
                }),
                take(1)
            )
        ).subscribe();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public trackByUid(index: number, item: IJob): ID {
        return item.id;
    }

    public reload(): void {
        if (!this.loading$.value) {
            this.load();
        }
    }

    public filter(event: Event): void {
        this.dataSource.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    private load(): void {
        this.loading$.next(true);

        this.jobService
            .slice({
                filter: location.search,
                pagination: {
                    pageSize: this.pageSize+10000,
                    page: this.paginator.pageIndex + 1
                }
            })
            .pipe(
                take(1),
                finalize(() => {
                    this.loading$.next(false);
                })
            ).subscribe((resp: IJobSlice): IJobSlice => {
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.dataSource.data = resp.data;
                this.sort.sort(({ id: 'createdDateTime', start: 'desc' }) as MatSortable);

                return resp;
            });
    }
}
