import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from '@modules/home/home.component';
import {ACCOUNT_ROUTES} from '@modules/accounts/accounts-routing.module';
import {COMPANY_ROUTES} from '@modules/companies/companies-routing.module';
import {APPLICATION_ROUTES} from '@modules/applications/applications-routing.module';
import {JOB_ROUTES} from '@modules/jobs/jobs-routing.module';
import {DashboardComponent} from '@modules/home/features/dashboard/dashboard.component';

export const HOME_ROUTES = {
    MODULE: '',
    ROOT: ''
};

const ROUTES: Routes = [
    {
        path: HOME_ROUTES.ROOT,
        component: HomeComponent,
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: ACCOUNT_ROUTES.MODULE,
                loadChildren: () => import('@modules/accounts/accounts.module').then(m => m.AccountsModule)
            },
            {
                path: COMPANY_ROUTES.MODULE,
                loadChildren: () => import('@modules/companies/companies.module').then(m => m.CompaniesModule)
            },
            {
                path: APPLICATION_ROUTES.MODULE,
                loadChildren: () => import('@modules/applications/applications.module').then(m => m.ApplicationsModule)
            },
            {
                path: JOB_ROUTES.MODULE,
                loadChildren: () => import('@modules/jobs/jobs.module').then(m => m.JobsModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class HomeRoutingModule {
}
