export enum AccountStatus {
  Newly = 'NEWLY',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum AccountRole {
  Bewerber = 'applicant',
  Admin = 'admin',
  'HR-Admin' = 'hr_admin',
  HR = 'hr',
}

export enum AccountLanguage {
  Deutsch = 'DE',
  Englisch = 'EN',
}
