import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, EMPTY, noop, Observable, race, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { IAccount, IAccountSlice } from '@core/interfaces';
import {
  debounceTime,
  delay,
  filter,
  finalize,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AccountService } from '@core/api/account.service';
import { FormControl } from '@angular/forms';
import { ObjectExt } from '@core/utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ID } from '@app/core/interfaces/snowflake.interface';

@Component({
  selector: 'lca-account-listing',
  templateUrl: './account-listing.component.html',
  styleUrls: ['./account-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountListingComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly destroyed$: Subject<void> = new Subject<void>();
  public readonly loading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public dataSource: MatTableDataSource<IAccount> =
    new MatTableDataSource<IAccount>([]);
  public columns: string[] = ['user', 'email', 'role', 'status', 'actions'];
  public pageSize = 10;
  public pageSizeOptions: number[] = [10, 50, 100];

  public groupSelectFilter = new FormControl<string>('');
  public textSearchFilter = new FormControl<string>('');
  private filteredValues: { role?: string; search?: string } = {};

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly accountService: AccountService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.load();

    this.groupSelectFilter.valueChanges
      .pipe(
        withLatestFrom(this.activatedRoute.queryParams),
        switchMap(([role, params]: [string, Params]) => {
          this.filteredValues['role'] = this.groupSelectFilter.value;
          console.log(this.filteredValues);
          this.dataSource.filter = JSON.stringify({
            ...this.filteredValues,
            role,
          });
          const p: Params = { ...params };
          if (!role.length) {
            delete p['role'];
          } else {
            p['role'] = role;
          }
          void this.router.navigate([], {
            queryParams: p,
            queryParamsHandling: p['role'] ? 'merge' : null,
          });
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();

    this.textSearchFilter.valueChanges
      .pipe(
        debounceTime(150),
        withLatestFrom(this.activatedRoute.queryParams),
        switchMap(([_, params]: [undefined, Params]) => {
          if (this.textSearchFilter.value) {
            this.filteredValues['search'] = this.textSearchFilter.value;
          } else {
            delete this.filteredValues['search'];
          }

          this.dataSource.filter = JSON.stringify(this.filteredValues);

          const p: Params = { ...params };
          if (!this.textSearchFilter.value) {
            delete p['search'];
          } else {
            p['search'] = this.textSearchFilter.value;
          }

          void this.router.navigate([], {
            queryParams: p,
            queryParamsHandling: p['search'] ? 'merge' : null,
          });

          if (this.dataSource.paginator && p.search === undefined) {
            this.paginator.firstPage();
          }
          return EMPTY;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  public ngAfterViewInit(): void {
    this.loading$
      .pipe(
        filter((v) => !v),
        withLatestFrom(
          this.activatedRoute.queryParams.pipe(
            filter((v) => !ObjectExt.isEmptyObject(v)),
            take(1)
          )
        ),
        switchMap(([_, params]: [boolean, Params]): Observable<never> => {
          if (params.search) {
            this.textSearchFilter.patchValue(params.search);
          }
          if (params.role) {
            this.groupSelectFilter.patchValue(params.role);
            this.filteredValues['role'] = params.role;
          }
          if (this.dataSource.paginator instanceof MatPaginator) {
            this.dataSource.paginator.pageSize = params.page_size;
            for (let i = 0; i < params.page_index; i++) {
              this.dataSource.paginator.nextPage();
            }
          }
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public trackByUid(_: number, item: IAccount): ID {
    return item.id;
  }

  public reload(): void {
    if (!this.loading$.value) {
      this.load();
    }
  }

  private load(): void {
    this.loading$.next(true);

    this.accountService
      .slice({
        filter: location.search,
        sort: `id:DESC`,
        pagination: {
          pageSize: this.pageSize,
          page: this.paginator.pageIndex + 1,
        },
      })
      .pipe(
        take(1),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((resp: any): any => {
        // TODO replace any with IAccountSlice
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.data = resp;

        return resp;
      });
  }

  private customFilterPredicate(): (data: any, filter: string) => boolean {
    return (data: any, filter: string): boolean => {
      const searchString = JSON.parse(filter);

      let globalMatch =
        !this.textSearchFilter.value && !this.groupSelectFilter.value;
      if (globalMatch) {
        return true;
      }

      let nameMatch = true;
      let emailMatch = true;
      let roleMatch = true;

      if (searchString.search) {
        const givenName = data.personalInfo?.givenName?.toLowerCase() || '';
        const familyName = data.personalInfo?.familyName?.toLowerCase() || '';
        const searchValue = searchString.search.toLowerCase();
        nameMatch =
          givenName.includes(searchValue) ||
          familyName.includes(searchValue) ||
          `${givenName} ${familyName}`.includes(searchValue) ||
          `${familyName} ${givenName}`.includes(searchValue);
        emailMatch = data.email?.toLowerCase().includes(searchValue);
      }

      if (searchString.role) {
        const roleValue = searchString.role.toLowerCase();
        const userRole = data.role?.name?.toLowerCase() || '';
        roleMatch = userRole.includes(roleValue);
      }

      return (nameMatch || emailMatch) && roleMatch;
    };
  }
}
