import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SignInComponent } from "@modules/auth/features/sign-in/sign-in.component";

export const AUTH_ROUTES = {
  MODULE: "auth",
  ROOT: "",
  SIGN_IN: "sign-in",
  SIGN_UP: "sign_up",
  ACCOUNT_RESET: "reset",
  HELP: "help",
};

const ROUTES: Routes = [
  {
    path: AUTH_ROUTES.ROOT,
    children: [
      {
        path: AUTH_ROUTES.SIGN_IN,
        component: SignInComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
