import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {JobListingComponent} from '@modules/jobs/features/job-listing/job-listing.component';
import {JobViewComponent} from '@modules/jobs/features/job-view/job-view.component';
import {JobResolver} from '@core/resolver';

export const JOB_ROUTES = {
    MODULE: 'jobs',
    ROOT: '',
    LISTING: '',
    VIEW: ':id'
};

const ROUTES: Routes = [
    {
        path: JOB_ROUTES.ROOT,
        component: JobListingComponent
    },
    {
        path: JOB_ROUTES.VIEW,
        component: JobViewComponent,
        resolve: {
            job: JobResolver
        }
    }
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
