import { IFile } from './../interfaces/file.interface';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { asapScheduler, Observable, scheduled, throwError } from 'rxjs';
import {
  FromApi,
  IAccount,
  IApplication,
  IApplicationSlice,
  IExperienceIn,
  IInCompanyTraining,
  Paginated,
} from '@core/interfaces';
import { ENVIRONMENT } from '@environment';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  map,
  tap,
  timeout,
} from 'rxjs/operators';
import { ID } from '../interfaces/snowflake.interface';
import * as qs from 'qs';
import {
  IDegrees,
  IDegreesType,
  LanguageInfo,
  LanguageLevels,
  LeveledLanguage,
} from '../interfaces/application.interface';
import { IEmployementType } from '../interfaces/employement-type.interface';
import { IDegree } from '../interfaces/degrees.interface';

interface SliceOptions {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  queryParams?: {
    [data: string]: string | string[];
  };
  sort?: string;
  filter?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api/applications`;
  private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;
  private readonly debounceTime: number = 1000;

  constructor(private readonly httpClient: HttpClient) {}

  public slice(options?: SliceOptions): Observable<IApplicationSlice> {
    const HTTP_PARAMS = new HttpParams({
      fromString: qs.stringify(
        {
          sort: options?.sort,
          populate: '*',
          search: options?.filter,
          pagination: {
            page: options?.pagination?.page ?? 1,
            pageSize: options?.pagination?.pageSize ?? 10000,
          },
        },
        {
          encodeValuesOnly: true,
        }
      ),
    });

    return this.httpClient
      .get<Paginated<FromApi<IApplication, 'personalInfo'>>>(this.endpoint, {
        withCredentials: true,
        params: HTTP_PARAMS,
      })
      .pipe(
        debounceTime(this.debounceTime),
        timeout(this.requestTimeout),
        distinctUntilChanged(),
        map(
          (value) =>
            ({
              meta: value.meta,
              data: value.data.map((a) => ({
                ...a.attributes,
                personalInfo: a.attributes.personalInfo.data
                  ? {
                      ...a.attributes.personalInfo.data.attributes,
                      id: a.attributes.personalInfo.data.id,
                    }
                  : null,
                id: a.id,
              })),
            } satisfies IApplicationSlice)
        )
      );
  }

  public get(id: ID): Observable<IApplication> {
    const REQUEST_URL = `${this.endpoint}/${id}`;

    const HTTP_PARAMS = new HttpParams({
      fromString: qs.stringify({
        populate: [
          'personalInfo',
          'personalInfo.user',
          'workExperience',
          'workExperience.companyTrainings',
          'workExperience.experienceIns',
          'degrees',
          'degrees.degrees',
          'languages',
          'languages.language',
          'languages.languageLevel',
          'resume',
          'portrait',
          'certificates',
          'lookingFors',
        ],
        locale: 'de',
      }),
    });

    return this.httpClient
      .get<{ data: FromApi<IApplication, 'personalInfo'> }>(REQUEST_URL, {
        withCredentials: true,
        params: HTTP_PARAMS,
      })
      .pipe(
        debounceTime(this.debounceTime),
        timeout(this.requestTimeout),
        distinctUntilChanged(),
        map((a) => {
          console.log('a', a);


          const { data: user } = a.data.attributes.personalInfo?.data?.attributes?.user as unknown as { data: FromApi<IAccount | null> } ?? {data: null};
          const { data: certificates } = a.data.attributes.certificates as unknown as { data: FromApi<IFile>[] } ?? {data: []};
          const { data: resume } = a.data.attributes.resume as unknown as {
            data: FromApi<IFile>;
          } ?? {data: null};
          const { data: portrait } = a.data.attributes.portrait as unknown as {
            data: FromApi<IFile>;
          } ?? {data: null};
          const { data: experienceIns } = a.data.attributes.workExperience?.experienceIns as unknown as { data: IExperienceIn[] } ?? {data: []};
          const { data: company_training } = a.data.attributes.workExperience?.companyTrainings as unknown as { data: IInCompanyTraining[] } ?? {data: []};
          const { data: lookingFors } = a.data.attributes?.lookingFors as unknown as { data: FromApi<IEmployementType>[] } ?? {data: []};
          const { data: degrees } = a.data.attributes.degrees?.degrees as unknown as { data: FromApi<IDegree>[] } ?? {data: []};

          return {
            ...a.data.attributes,
            personalInfo: {
              ...a.data.attributes.personalInfo?.data?.attributes,
              user: user
                ? {
                    ...user?.attributes,
                    id: user?.id,
                  }
                : undefined,
              id: a.data.attributes.personalInfo?.data?.id,
            },
            workExperience: {
              ...a.data.attributes.workExperience,
              companyTrainings: company_training.map((ct) => {
                const training = ct as unknown as FromApi<IInCompanyTraining>;
                return {
                  ...training.attributes,
                  id: training.id,
                } satisfies IInCompanyTraining;
              }),
              experienceIns: experienceIns.map((ei) => {
                const exp_in = ei as undefined as FromApi<IExperienceIn>;
                return {
                  ...exp_in.attributes,
                  id: exp_in.id,
                };
              }),
            },
            degrees: a.data.attributes.degrees
              ? ({
                  type: a.data.attributes.degrees?.type,
                  degrees: degrees.map((d) => {
                    return {
                      ...d.attributes,
                      id: d.id,
                    } satisfies IDegree;
                  }),
                } satisfies IDegrees)
              : undefined,
            languages: a.data.attributes.languages?.filter((l: any) => {
              return l.language?.data?.id && l.language?.data?.attributes && l.languageLevel?.data?.id && l.languageLevel?.data?.attributes;
            }).map((l: any) => {
              const language = l.language as unknown as {
                data: FromApi<LanguageInfo>;
              };
              const level = l.languageLevel as unknown as { data: any }; // TODO replace any with FromApi<LanguageLevels>
              return {
                level: {
                  ...level.data.attributes,
                  id: level.data.id,
                },
                language: {
                  ...language.data.attributes,
                  id: language.data.id,
                },
              } satisfies LeveledLanguage;
            }),
            certificates: certificates?.map((c) => {
              const certifs = c as unknown as FromApi<IFile>;
              return {
                ...certifs.attributes,
                id: certifs.id,
              };
            }),
            lookingFors: lookingFors?.map((lf) => {
              return {
                ...lf.attributes,
                id: lf.id,
              };
            }),
            resume: {
              ...resume?.attributes,
              id: resume?.id,
            },
            portrait: {
              ...portrait?.attributes,
              id: portrait?.id,
            },
            id: a.data?.id,
          } satisfies IApplication;
        })
      );
  }

  public activateProfile<T extends boolean>(accountUID: ID): Observable<T> {
    const REQUEST_URL = `${ENVIRONMENT.api.gatewayBaseUrl}/${ENVIRONMENT.api.apiVersion}/activate/${accountUID}`;

    const HTTP_HEADERS = new HttpHeaders({});
    const HTTP_PARAMS = new HttpParams({});

    return this.httpClient
      .put<T>(
        REQUEST_URL,
        { items: [] },
        {
          withCredentials: true,
          observe: 'response',
          responseType: 'json',
          headers: HTTP_HEADERS,
          params: HTTP_PARAMS,
        }
      )
      .pipe(
        debounceTime(this.debounceTime),
        timeout(this.requestTimeout),
        distinctUntilChanged(),
        exhaustMap((resp: HttpResponse<T>): Observable<T> => {
          return scheduled(
            [(resp.status === 204) as T],
            asapScheduler
          ) as Observable<T>;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
  }
}
